import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createSlots as _createSlots } from "vue"
import _imports_0 from '@/assets/icons/home-outline.svg'
import _imports_1 from '@/assets/icons/multi-property-icon.png'
import _imports_2 from '@/assets/icons/calendar.svg'
import _imports_3 from '@/assets/icons/moon.svg'
import _imports_4 from '@/assets/icons/guest-icon.png'
import _imports_5 from '@/assets/icons/pet-icon.png'


const _hoisted_1 = { style: {"display":"flex","align-items":"center","padding":"2px 0"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  lines: "none",
  style: {"max-width":"500px","margin":"auto"}
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  "data-v-4262366e": "",
  title: "Paid In Full",
  class: "reservation-status-marker mr-2",
  style: {"background":"rgb(0, 153, 0)"},
  slot: "end"
}
const _hoisted_6 = {
  key: 1,
  "data-v-4262366e": "",
  title: "Paid",
  class: "reservation-status-marker mr-2",
  style: {"background":"rgb(0, 153, 0)"},
  slot: "end"
}
const _hoisted_7 = {
  key: 2,
  "data-v-4262366e": "",
  title: "Confirmed",
  class: "reservation-status-marker mr-2",
  style: {"background":"linear-gradient(\n                                                transparent 0%,\n                                                transparent 50%,\n                                                rgb(255, 170, 0) 50%,\n                                                rgb(255, 170, 0) 100%\n                                            )","border":"2px solid rgb(255, 170, 0)"},
  slot: "end"
}
const _hoisted_8 = {
  key: 3,
  "data-v-4262366e": "",
  title: "Unconfirmed",
  class: "reservation-status-marker mr-2",
  style: {"border":"2px solid rgb(204, 0, 0)"},
  slot: "end"
}
const _hoisted_9 = {
  key: 4,
  "data-v-4262366e": "",
  title: "Cancelled",
  class: "reservation-status-marker mr-2",
  style: {"background":"rgb(158, 158, 158)","border-radius":"0px"},
  slot: "end"
}
const _hoisted_10 = {
  key: 0,
  slot: "start",
  style: {"margin-inline-end":"0px"}
}
const _hoisted_11 = {
  key: 0,
  style: {"width":"100px","height":"40px"},
  src: _imports_0
}
const _hoisted_12 = ["src"]
const _hoisted_13 = {
  key: 2,
  style: {"width":"100px","border-radius":"10px"},
  src: _imports_1
}
const _hoisted_14 = {
  key: 1,
  slot: "start"
}
const _hoisted_15 = { key: 0 }
const _hoisted_16 = {
  style: {"font-weight":"500","font-size":"small","padding-left":"15px"},
  "text-wrap": ""
}
const _hoisted_17 = { key: 1 }
const _hoisted_18 = {
  style: {"font-weight":"500","font-size":"small","padding-left":"15px"},
  "text-wrap": ""
}
const _hoisted_19 = { key: 2 }
const _hoisted_20 = {
  style: {"font-weight":"500","font-size":"small","padding-left":"15px"},
  "text-wrap": ""
}
const _hoisted_21 = { key: 3 }
const _hoisted_22 = {
  style: {"font-weight":"500","font-size":"small","padding-left":"15px"},
  "text-wrap": ""
}
const _hoisted_23 = { key: 4 }
const _hoisted_24 = {
  style: {"font-weight":"500","font-size":"small","padding-left":"15px"},
  "text-wrap": ""
}
const _hoisted_25 = {
  style: {"padding-left":"2px"},
  class: "ldx-quick-amenities"
}
const _hoisted_26 = { class: "ldx-quick-amenity" }
const _hoisted_27 = { class: "ldx-quick-amenity" }
const _hoisted_28 = { class: "ldx-quick-amenity" }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = {
  key: 0,
  class: "ldx-quick-amenity"
}
const _hoisted_31 = { key: 1 }
const _hoisted_32 = {
  key: 0,
  style: {"font-weight":"500","font-size":"small","padding-left":"15px"},
  "text-wrap": ""
}
const _hoisted_33 = { ref: "endOfReservationList" }
const _hoisted_34 = { style: {"display":"flex","justify-content":"center","align-items":"center","margin-bottom":"20px"} }
const _hoisted_35 = {
  key: 1,
  style: {"height":"55%","width":"100%","display":"flex","justify-content":"center","align-items":"center","flex-direction":"column"}
}
const _hoisted_36 = {
  key: 2,
  style: {"display":"flex","justify-content":"center","align-items":"center","margin-bottom":"20px"}
}
const _hoisted_37 = { style: {"display":"flex","justify-content":"center","align-items":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, {
    "page-title": "View Reservations",
    lightBlueBackground: "",
    removeMode: ""
  }, _createSlots({
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_37, [
        _createVNode(_component_ion_label, { style: {"font-size":"larger"} }, {
          default: _withCtx(() => [
            _createTextVNode(" Total: " + _toDisplayString(_ctx.total) + " " + _toDisplayString(_ctx.getLabelText), 1)
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      (_ctx.reservationList.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reservationList, (item, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                (item.id != (_ctx.reservationList[index - 1]?.id || -1))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      (item.state != 'BLOCKED')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createVNode(_component_ion_card, {
                              mode: "ios",
                              onClick: ($event: any) => (_ctx.ModalController.controlPanelModal(item.id)),
                              style: {"margin":"10px","margin-bottom":"15px","margin-top":"15px","cursor":"pointer"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_content, { style: {"padding":"0px","margin-bottom":"5px"} }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_item, {
                                      style: {"height":"35px"},
                                      lines: "none",
                                      class: "ion-no-padding"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_label, {
                                          "text-wrap": "",
                                          style: {"font-weight":"700","padding-left":"20px","font-size":"medium","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.guest.full_name), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_ion_label, {
                                          slot: "end",
                                          style: {"font-size":"small"}
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("Invoice #" + _toDisplayString(item.id), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        (item.state == 'PAID' && item.substate == 'PAID')
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                                          : _createCommentVNode("", true),
                                        (item.state == 'PAID' && item.substate != 'PAID')
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                                          : _createCommentVNode("", true),
                                        (item.state == 'PAIDDEPOSIT')
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_7))
                                          : _createCommentVNode("", true),
                                        (item.state == 'NOTPAID')
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_8))
                                          : _createCommentVNode("", true),
                                        (item.state == 'CANCELLED')
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_9))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_ion_item, {
                                      lines: "none",
                                      style: {"align-items":"center","display":"flex"}
                                    }, {
                                      default: _withCtx(() => [
                                        (item.properties.length > 0)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                              (!item.properties[0].thumbnail_url && item.properties.length == 1)
                                                ? (_openBlock(), _createElementBlock("img", _hoisted_11))
                                                : _createCommentVNode("", true),
                                              (item.properties[0].thumbnail_url && item.properties.length == 1)
                                                ? (_openBlock(), _createElementBlock("img", {
                                                    key: 1,
                                                    style: {"height":"75px","border-radius":"10px"},
                                                    src: item.properties[0].thumbnail_url
                                                  }, null, 8, _hoisted_12))
                                                : _createCommentVNode("", true),
                                              (item.properties.length > 1)
                                                ? (_openBlock(), _createElementBlock("img", _hoisted_13))
                                                : _createCommentVNode("", true)
                                            ]))
                                          : _createCommentVNode("", true),
                                        (item.properties.length <= 0)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[2] || (_cache[2] = [
                                              _createElementVNode("img", {
                                                style: {"width":"100px","height":"40px"},
                                                src: _imports_0
                                              }, null, -1)
                                            ])))
                                          : _createCommentVNode("", true),
                                        _createVNode(_component_ion_list, {
                                          slot: "end",
                                          style: {"width":"100%","margin-bottom":"10px"}
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.properties, (list, index) => {
                                              return (_openBlock(), _createElementBlock("div", {
                                                style: {"font-weight":"700","font-size":"small","padding-left":"15px"},
                                                "text-wrap": "",
                                                key: index
                                              }, _toDisplayString(list.name), 1))
                                            }), 128)),
                                            (item.state == 'PAIDDEPOSIT')
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                                  _createElementVNode("p", _hoisted_16, [
                                                    _createTextVNode(_toDisplayString(item.booking_method_name) + " · ", 1),
                                                    _cache[3] || (_cache[3] = _createElementVNode("span", { style: {"color":"rgb(255, 170, 0)"} }, "Confirmed", -1))
                                                  ])
                                                ]))
                                              : _createCommentVNode("", true),
                                            (item.state == 'PAID' && item.substate == 'PAID')
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                                  _createElementVNode("p", _hoisted_18, [
                                                    _createTextVNode(_toDisplayString(item.booking_method_name) + " · ", 1),
                                                    _cache[4] || (_cache[4] = _createElementVNode("span", { style: {"color":"rgb(0, 153, 0)"} }, "Paid In Full", -1))
                                                  ])
                                                ]))
                                              : _createCommentVNode("", true),
                                            (item.state == 'PAID' && item.substate != 'PAID')
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                                  _createElementVNode("p", _hoisted_20, [
                                                    _createTextVNode(_toDisplayString(item.booking_method_name) + " · ", 1),
                                                    _cache[5] || (_cache[5] = _createElementVNode("span", { style: {"color":"rgb(0, 153, 0)"} }, "Paid", -1))
                                                  ])
                                                ]))
                                              : _createCommentVNode("", true),
                                            (item.state == 'NOTPAID')
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                                  _createElementVNode("p", _hoisted_22, [
                                                    _createTextVNode(_toDisplayString(item.booking_method_name) + " · ", 1),
                                                    _cache[6] || (_cache[6] = _createElementVNode("span", { style: {"color":"rgb(204, 0, 0)"} }, "Unconfirmed", -1))
                                                  ])
                                                ]))
                                              : _createCommentVNode("", true),
                                            (item.state == 'CANCELLED')
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                                  _createElementVNode("p", _hoisted_24, [
                                                    _createTextVNode(_toDisplayString(item.booking_method_name) + " · ", 1),
                                                    _cache[7] || (_cache[7] = _createElementVNode("span", { style: {"color":"rgb(113, 113, 113)"} }, "Cancelled", -1))
                                                  ])
                                                ]))
                                              : _createCommentVNode("", true),
                                            _createElementVNode("div", _hoisted_25, [
                                              _createElementVNode("div", _hoisted_26, [
                                                _cache[8] || (_cache[8] = _createElementVNode("img", { src: _imports_2 }, null, -1)),
                                                _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(item.from_date)), 1)
                                              ]),
                                              _createElementVNode("div", _hoisted_27, [
                                                _cache[9] || (_cache[9] = _createElementVNode("img", { src: _imports_3 }, null, -1)),
                                                _createElementVNode("span", null, _toDisplayString(item.nights), 1)
                                              ]),
                                              _createElementVNode("div", _hoisted_28, [
                                                _cache[10] || (_cache[10] = _createElementVNode("img", { src: _imports_4 }, null, -1)),
                                                _createElementVNode("span", null, _toDisplayString(item.guests + item.children), 1)
                                              ]),
                                              (item.properties[0])
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                                    (item.properties[0].number_of_pets > 0)
                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                                                          _cache[11] || (_cache[11] = _createElementVNode("img", { src: _imports_5 }, null, -1)),
                                                          _createElementVNode("span", null, _toDisplayString(item.properties[0].number_of_pets), 1)
                                                        ]))
                                                      : _createCommentVNode("", true)
                                                  ]))
                                                : _createCommentVNode("", true)
                                            ])
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]))
                        : _createCommentVNode("", true),
                      (item.state == 'BLOCKED')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                            _createVNode(_component_ion_card, {
                              mode: "ios",
                              style: {"margin":"10px","margin-bottom":"15px","margin-top":"15px"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_content, { style: {"padding":"0px","margin":"5px"} }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_item, {
                                      style: {"height":"35px"},
                                      lines: "none",
                                      class: "ion-no-padding"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_label, {
                                          "text-wrap": "",
                                          style: {"font-weight":"700","padding-left":"20px"}
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.formatDate(item.from_date)) + " - " + _toDisplayString(_ctx.formatDate(item.to_date)), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_ion_item, { lines: "none" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_icon, {
                                          style: {"width":"75px","margin-bottom":"10px"},
                                          color: "medium",
                                          src: _ctx.closeCircle
                                        }, null, 8, ["src"]),
                                        _createVNode(_component_ion_list, { style: {"width":"100%","margin-bottom":"10px"} }, {
                                          default: _withCtx(() => [
                                            _cache[12] || (_cache[12] = _createElementVNode("p", {
                                              style: {"font-weight":"700","font-size":"medium","padding-left":"15px","color":"red"},
                                              "text-wrap": ""
                                            }, " BLOCKED ", -1)),
                                            (item.properties[0])
                                              ? (_openBlock(), _createElementBlock("p", _hoisted_32, _toDisplayString(item.properties[0].name), 1))
                                              : _createCommentVNode("", true)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128)),
            _createElementVNode("div", _hoisted_33, null, 512)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_34, [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_ion_spinner, {
              key: 0,
              color: "primary",
              name: "circles"
            }))
          : _createCommentVNode("", true)
      ]),
      (_ctx.total <= 0 && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
            _createVNode(_component_ion_icon, {
              icon: _ctx.getNoItemsIcon,
              style: {"width":"55px","height":"55px","margin-bottom":"10px"},
              color: "medium"
            }, null, 8, ["icon"]),
            _createVNode(_component_ion_label, { style: {"font-size":"large","font-weight":"700"} }, {
              default: _withCtx(() => [
                _createTextVNode("No " + _toDisplayString(_ctx.getLabelText) + " " + _toDisplayString(_ctx.searchValue != '' ? 'Found' : ''), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_label, { style: {"font-size":"medium","font-weight":"400"} }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode("Try adjusting your search criteria")
              ])),
              _: 1
            })
          ]))
        : (_ctx.reservationList.length >= _ctx.total && _ctx.reservationList.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode("No More Results")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.allowFilter)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ion_searchbar, {
                placeholder: 'Search ' + (_ctx.filters.type ? 'Blocks' : 'Reservations'),
                debounce: 250,
                value: _ctx.searchValue,
                onIonInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.search($event))),
                style: {"padding":"0px 5px"}
              }, null, 8, ["placeholder", "value"]),
              _createVNode(_component_ion_button, {
                color: "primary",
                style: {"max-width":"fit-content","aspect-ratio":"1/1","padding":"2px"},
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateFilters()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.filter }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}