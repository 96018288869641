import axios from 'axios';
import { useStore } from '@/store/store';
import LodgixOAuth2 from '@/services/OAuth2';
import { accessToken, refreshToken } from '@/services/OAuth2';
import * as Sentry from '@sentry/capacitor';

const urlBase = process.env.VUE_APP_URL_BASE;

export default (url = urlBase + '/o') => {
    const store = useStore();
    const api = axios.create({
        baseURL: url,
        headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
        },
    });

    api.interceptors.request.use(async (config) => {
        if (accessToken.value) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            config.headers!.Authorization = `Bearer ${accessToken.value}`;
        }
        return config;
    });

    api.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const originalRequest = error.config;
            if (error.response?.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                if (!refreshToken.value) {
                    //Sentry.captureException(error);
                    return Promise.reject(error);
                }
                try {
                    const didRefresh = await LodgixOAuth2.refresh();
                    if (!didRefresh) {
                        Sentry.captureMessage(
                            'Failed to refresh token, refresh method returned false. Logging user out.'
                        );
                        await LodgixOAuth2.logout();
                    }
                    originalRequest.headers.Authorization = `Bearer ${accessToken.value}`;
                    return api(originalRequest);
                } catch (e) {
                    //Sentry.captureException(e);
                    Sentry.captureMessage('Failed to refresh token due to try/catch block. Logging user out.');
                    await LodgixOAuth2.logout();
                    return Promise.reject(e);
                }
            } else if (!error.response) {
                // Network error
                Sentry.captureMessage('Network error occurred.');
                Sentry.captureException(error);
            } else {
                // Other errors
                //Sentry.captureException(error);
            }

            // for 404 errors return null
            if (error.response?.status === 404) {
                return null;
            }

            return Promise.reject(error);
        }
    );

    return api;
};
