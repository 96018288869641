<template>
    <ion-page mode="ios">
        <ion-header v-if="signedIn()" class="ion-no-border">
            <ion-toolbar color="primary" mode="md">
                <ion-buttons slot="start">
                    <ion-menu-button></ion-menu-button>
                </ion-buttons>
                <ion-title></ion-title>
                <ion-thumbnail style="cursor: pointer" slot="end" href="/">
                    <img src="./../assets/img/lodgix-icon-white.svg" />
                </ion-thumbnail>
            </ion-toolbar>
        </ion-header>

        <LoginPage v-if="!signedIn()"></LoginPage>

        <ion-content
            v-if="signedIn()"
            class="main-content"
            style="display: flex; justify-content: center; --overflow: hidden"
        >
            <div v-if="housekeeperGroup()">
                <HousekeeperComponent />
            </div>

            <div v-else>
                <MainMenu />
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { alertCircle } from 'ionicons/icons';
    import { defineComponent, onMounted, watch, ref } from 'vue';
    import AppFunctions from '@/services/AppFunctions';
    import LoginPage from '@/components/LoginPage.vue';
    import format from 'date-fns/format/index';
    import { setupFCM } from '@/services/FCM';
    import HousekeeperComponent from '@/components/housekeeper/HousekeeperComponentView.vue';
    import { useStore } from '@/store/store';
    import { Capacitor } from '@capacitor/core';
    import { Intercom } from '@capacitor-community/intercom';
    import AppAPI from '@/services/AppAPI';
    import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
    import { Device } from '@capacitor/device';
    import { InAppReview } from '@capacitor-community/in-app-review';
    import { hideLoading, showLoading } from '@/services/Loading';
    import {
        housekeeperGroup,
        adminAppWelcomePopup,
        ownerGroup,
        reservationAccess,
        widgetAccess,
    } from '@/types/accessGroups';
    import MainMenu from '@/components/MainMenu.vue';
    import { SplashScreen } from '@capacitor/splash-screen';
    import OAuth2, { accessToken } from '@/services/OAuth2';

    export default defineComponent({
        name: 'HomePage',
        components: {
            LoginPage,
            HousekeeperComponent,
            MainMenu,
        },
        setup() {
            var date = format(new Date(), 'MM/dd/yyyy');
            const ionRouter = useIonRouter();
            const store = useStore();
            const lang = ref(store.lang);

            onMounted(async () => {
                if (store.firstOpenApp == '1') {
                    if (reservationAccess()) {
                        AppFunctions.clearReservationStorage();
                    }
                    await AppFunctions.loadWidgetData();
                    if (accessToken.value) {
                        await AppFunctions.updatePushNotificationSystem();
                        await AppFunctions.getUserInfo(true);
                        await OAuth2.updateScopes();
                    }
                }

                if (!signedIn()) {
                    await SplashScreen.hide();
                }
            });

            const signedIn = () => {
                return AppFunctions.isSignedIn();
            };

            const getDeviceModel = async () => {
                const deviceInfo = await Device.getInfo();
                const deviceModel = deviceInfo.model;
                return deviceModel;
            };

            const getDevice = async () => {
                const deviceInfo = await Device.getInfo();
                let tmpDevice = null;

                try {
                    tmpDevice = await AppAPI.getDeviceById(store.PushNotificationToken);
                } catch (error) {
                    console.error('An error occurred while fetching the device:', error);
                }

                let deviceName = deviceInfo.name;
                if (tmpDevice?.data?.name) {
                    deviceName = tmpDevice.data.name;
                }
                if (deviceName == undefined) {
                    deviceName = 'Unknown Device';
                }

                let deviceActive = true;
                if (tmpDevice?.data?.active != undefined || tmpDevice?.data?.active != null) {
                    deviceActive = tmpDevice.data.active;
                }

                const deviceData = {
                    name: deviceName,
                    active: deviceActive,
                };

                return deviceData;
            };

            const changeLanguage = ($event: any) => {
                store.changeLang($event.detail.value);
            };

            watch(
                () => store.UserFirstName,
                async (val) => {
                    if (store.UserFirstName == '') {
                        return;
                    }

                    let loadingService = null;
                    if (Capacitor.getPlatform() != 'ios') {
                        loadingService = await showLoading('Loading...');
                    }
                    await AppFunctions.updatePushNotificationSystem();
                    await AppFunctions.loadWidgetData();
                    if (Capacitor.getPlatform() === 'web') {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        hideLoading(loadingService!);
                        return;
                    }
                    try {
                        await AppFunctions.loadIntercom();
                    } catch (error) {
                        console.log('Intercom Error: ', error);
                    }
                    if (store.PushNotificationToken == '') {
                        setupFCM();
                    }
                    if (store.WelcomeStatus != 'no' && adminAppWelcomePopup()) {
                        Intercom.displayCarousel({ carouselId: '32137014' });
                        SecureStoragePlugin.set({ key: 'welcomePopup', value: 'no' });
                    } else if (store.WelcomeStatusHousekeeper != 'no' && housekeeperGroup()) {
                        Intercom.displayCarousel({ carouselId: '33476189' });
                        SecureStoragePlugin.set({ key: 'welcomePopupHousekeeper', value: 'no' });
                    } else {
                        if (
                            store.ReviewStatus == '' ||
                            store.ReviewStatus == null ||
                            parseInt(store.ReviewStatus) < parseInt(format(new Date(), 'yyyy'))
                        ) {
                            InAppReview.requestReview();
                            let year = format(new Date(), 'yyyy');
                            store.changeReviewStatus(year);
                        }
                    }

                    if (Capacitor.getPlatform() != 'ios') {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        hideLoading(loadingService!);
                    } else {
                        await SplashScreen.hide();
                    }
                }
            );

            watch(
                () => store.PushNotificationToken,
                async (val) => {
                    if (accessToken.value) {
                        const model = await getDeviceModel();
                        const device = await getDevice();

                        await AppAPI.createDevice(
                            device.name,
                            store.PushNotificationToken,
                            model,
                            Capacitor.getPlatform(),
                            device.active
                        );
                        if (Capacitor.getPlatform() === 'android') {
                            Intercom.sendPushTokenToIntercom({ value: store.PushNotificationToken });
                        }
                    }
                }
            );

            return {
                date,
                ionRouter,
                alertCircle,
                store,
                signedIn,
                getDeviceModel,
                housekeeperGroup,
                adminAppWelcomePopup,
                ownerGroup,
                changeLanguage,
                lang,
            };
        },
    });
</script>

<style scoped>
    .ldx-header-icon {
        padding-right: 20px;
        position: relative;
        margin: -8px;
        height: 30px;
        width: 30px;
        margin-left: -5px;
    }
    ion-select::part(icon) {
        color: white;
    }
</style>
