import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/icons/vip.svg'
import _imports_1 from '@/assets/icons/blocklist.svg'


const _hoisted_1 = {
  key: 0,
  slot: "end",
  style: {"padding-right":"0px","margin-left":"10px"},
  src: _imports_0
}
const _hoisted_2 = {
  key: 1,
  slot: "end",
  style: {"padding-right":"0px","margin-left":"10px"},
  src: _imports_1
}
const _hoisted_3 = {
  class: "address",
  slot: "end"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, { lines: "full" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Name")
            ])),
            _: 1
          }),
          _createVNode(_component_ion_label, {
            "text-wrap": "",
            slot: "end",
            style: {"text-align":"end"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.guest?.full_name), 1)
            ]),
            _: 1
          }),
          (_ctx.data.guest?.is_vip)
            ? (_openBlock(), _createElementBlock("img", _hoisted_1))
            : _createCommentVNode("", true),
          (_ctx.data.guest?.is_blocklist)
            ? (_openBlock(), _createElementBlock("img", _hoisted_2))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_ctx.data.guest?.phone)
        ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("Email")
                ])),
                _: 1
              }),
              _createVNode(_component_ion_label, {
                "text-wrap": "",
                slot: "end",
                style: {"text-align":"end"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.data.guest?.email), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_icon, {
                slot: "end",
                style: {"margin-left":"10px","cursor":"pointer"},
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.AppFunctions.openEmail(_ctx.data.guest?.email))),
                icon: _ctx.mailOutline
              }, null, 8, ["icon"])
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_ion_item, {
            key: 1,
            style: {"border-bottom":"none"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Email")
                ])),
                _: 1
              }),
              _createVNode(_component_ion_label, {
                "text-wrap": "",
                slot: "end",
                style: {"text-align":"end"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.data.guest?.email), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_icon, {
                slot: "end",
                style: {"margin-left":"10px","cursor":"pointer"},
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.AppFunctions.openEmail(_ctx.data.guest?.email))),
                icon: _ctx.mailOutline
              }, null, 8, ["icon"])
            ]),
            _: 1
          })),
      (_ctx.data.guest?.phone)
        ? (_openBlock(), _createBlock(_component_ion_item, {
            key: 2,
            style: {"border-bottom":"none"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Phone")
                ])),
                _: 1
              }),
              _createVNode(_component_ion_label, {
                "text-wrap": "",
                slot: "end",
                style: {"text-align":"end"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.data.guest?.phone), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_icon, {
                slot: "end",
                style: {"margin-left":"10px","cursor":"pointer"},
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.AppFunctions.openPhone(_ctx.data.guest?.phone))),
                icon: _ctx.callOutline
              }, null, 8, ["icon"]),
              _createVNode(_component_ion_icon, {
                slot: "end",
                style: {"margin-left":"10px","cursor":"pointer"},
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.AppFunctions.openText(_ctx.data.guest?.phone))),
                icon: _ctx.chatboxEllipsesOutline
              }, null, 8, ["icon"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.data.guest)
        ? (_openBlock(), _createBlock(_component_ion_item, { key: 3 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("Address")
                ])),
                _: 1
              }),
              _createElementVNode("div", _hoisted_3, [
                (_ctx.data.guest.address.address1)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.data.guest.address.address1), 1))
                  : _createCommentVNode("", true),
                (_ctx.data.guest.address.address2)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.data.guest.address.address2), 1))
                  : _createCommentVNode("", true),
                (_ctx.data.guest.address.city || _ctx.data.guest.address.state?.code || _ctx.data.guest.address.zip)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.data.guest.address.city) + ", " + _toDisplayString(_ctx.data.guest.address.state?.code || '') + " " + _toDisplayString(_ctx.data.guest.address.zip), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("p", null, _toDisplayString(_ctx.data.guest.address.country?.name || ''), 1)
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}