import { resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ReservationDetailsSegment = _resolveComponent("ReservationDetailsSegment")!
  const _component_GuestDetailsSegment = _resolveComponent("GuestDetailsSegment")!
  const _component_PaymentSummarySegment = _resolveComponent("PaymentSummarySegment")!
  const _component_HousekeepingSegment = _resolveComponent("HousekeepingSegment")!
  const _component_TriggersSegment = _resolveComponent("TriggersSegment")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_ctx.data)
    ? (_openBlock(), _createBlock(_component_modal_base_layout, {
        key: 0,
        ref: "modalBaseLayoutRef",
        title: "Control Panel",
        background: "rgb(239, 246, 255)"
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        class: "modal-footer-button",
                        color: "danger",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.modalBaseLayoutRef.cancel()))
                      }, {
                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                          _createTextVNode("Close")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        class: "modal-footer-button",
                        color: "primary",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.createNewReservation()))
                      }, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createTextVNode("Add Reservation")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.data.conversationId)
                    ? (_openBlock(), _createBlock(_component_ion_col, {
                        key: 0,
                        style: {"max-width":"fit-content !important","aspect-ratio":"1/1"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            style: {"width":"fit-content !important"},
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openConversation(_ctx.data.conversationId)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                src: _ctx.chatbubbles,
                                style: {"width":"24px","height":"24px"}
                              }, null, 8, ["src"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          (_ctx.statusDetails)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.statusDetails.class),
                style: _normalizeStyle(_ctx.statusDetails.style)
              }, [
                _createElementVNode("p", {
                  style: _normalizeStyle(_ctx.statusDetails.titleStyle)
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.statusDetails.icon), {
                    style: _normalizeStyle(_ctx.statusDetails.imgStyle)
                  }, null, 8, ["style"])),
                  _createTextVNode(_toDisplayString(_ctx.statusDetails.title), 1)
                ], 4),
                _createElementVNode("p", null, [
                  _createTextVNode(" Reservation ID: #" + _toDisplayString(_ctx.data.reservationId) + " ", 1),
                  _createVNode(_component_ion_icon, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.AppFunctions.copyText(_ctx.data.reservationId))),
                    style: {"margin-bottom":"-2px","cursor":"pointer"},
                    icon: _ctx.copyOutline,
                    label: "Copy Reservation ID"
                  }, null, 8, ["icon"]),
                  _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                  _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.data.bookingMethodName) + " • " + _toDisplayString(_ctx.data.createdDate), 1)
                ])
              ], 6))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_segment, {
            mode: "md",
            scrollable: true,
            modelValue: _ctx.selectedSegment,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSegment) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_segment_button, { value: "reservation-details" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode("Reservation Details")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_segment_button, { value: "guest-details" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode("Guest Information")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_segment_button, { value: "payment-summary" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode("Payment Summary")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_segment_button, { value: "triggers" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode("Triggers")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_segment_button, { value: "housekeeping" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createTextVNode("Housekeeping")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          (_ctx.selectedSegment == 'reservation-details')
            ? (_openBlock(), _createBlock(_component_ReservationDetailsSegment, {
                key: 1,
                data: _ctx.data,
                isMultipleProperties: _ctx.isMultipleProperties,
                tmpNotes: _ctx.tmpNotes,
                invoiceNumber: _ctx.invoiceNumber,
                onFetchData: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["data", "isMultipleProperties", "tmpNotes", "invoiceNumber"]))
            : _createCommentVNode("", true),
          (_ctx.selectedSegment == 'guest-details')
            ? (_openBlock(), _createBlock(_component_GuestDetailsSegment, {
                key: 2,
                data: _ctx.data
              }, null, 8, ["data"]))
            : _createCommentVNode("", true),
          (_ctx.selectedSegment == 'payment-summary')
            ? (_openBlock(), _createBlock(_component_PaymentSummarySegment, {
                key: 3,
                data: _ctx.data
              }, null, 8, ["data"]))
            : _createCommentVNode("", true),
          (_ctx.selectedSegment == 'housekeeping')
            ? (_openBlock(), _createBlock(_component_HousekeepingSegment, {
                key: 4,
                data: _ctx.data,
                modalBaseLayoutRef: _ctx.modalBaseLayoutRef,
                onFetchData: _cache[3] || (_cache[3] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["data", "modalBaseLayoutRef"]))
            : _createCommentVNode("", true),
          (_ctx.triggers && _ctx.selectedSegment == 'triggers')
            ? (_openBlock(), _createBlock(_component_TriggersSegment, {
                key: 5,
                triggers: _ctx.triggers,
                isMultipleProperties: _ctx.isMultipleProperties,
                invoiceNumber: _ctx.invoiceNumber,
                onGetTriggers: _ctx.getTriggers
              }, null, 8, ["triggers", "isMultipleProperties", "invoiceNumber", "onGetTriggers"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 512))
    : _createCommentVNode("", true)
}