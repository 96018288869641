import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { style: {"color":"black"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, { lines: "full" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.properties, (item, index) => {
        return (_openBlock(), _createBlock(_component_ion_item, { key: index }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode("Property " + _toDisplayString(_ctx.isMultipleProperties ? index + 1 : ''), 1)
              ]),
              _: 2
            }, 1024),
            (item.room_id)
              ? (_openBlock(), _createBlock(_component_ion_label, {
                  key: 0,
                  slot: "end",
                  style: {"text-align":"end"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name) + " - " + _toDisplayString(item.room_id), 1)
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true),
            (!item.room_id)
              ? (_openBlock(), _createBlock(_component_ion_label, {
                  key: 1,
                  slot: "end",
                  style: {"text-align":"end"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024))
      }), 128)),
      _createVNode(_component_ion_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Adults")
            ])),
            _: 1
          }),
          _createVNode(_component_ion_label, {
            slot: "end",
            style: {"text-align":"end"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.guests), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Children")
            ])),
            _: 1
          }),
          _createVNode(_component_ion_label, {
            slot: "end",
            style: {"text-align":"end"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.children), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Pets")
            ])),
            _: 1
          }),
          _createVNode(_component_ion_label, {
            slot: "end",
            style: {"text-align":"end"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.reservationNumberOfPetsTotal), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Arrival")
            ])),
            _: 1
          }),
          _createVNode(_component_ion_label, {
            slot: "end",
            style: {"text-align":"end"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.data.startDate)) + " ", 1),
              (_ctx.data.checkInTime)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, "• " + _toDisplayString(_ctx.formatTime(_ctx.data.checkInTime)), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Departure")
            ])),
            _: 1
          }),
          _createVNode(_component_ion_label, {
            slot: "end",
            style: {"text-align":"end"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.data.endDate)) + " ", 1),
              (_ctx.data.checkOutTime)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "• " + _toDisplayString(_ctx.formatTime(_ctx.data.checkOutTime)), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _cache[6] || (_cache[6] = _createElementVNode("h3", null, "Invoice Notes", -1)),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.tmpNotes || 'N/A'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateNotes())),
            slot: "end",
            style: {"max-width":"40px","max-height":"40px","height":"40px","width":"40px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { src: _ctx.pencilOutline }, null, 8, ["src"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}