import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { style: {"width":"20px !important","height":"20px !important","display":"flex","object-fit":"contain"} }
const _hoisted_2 = {
  "aria-hidden": "true",
  focusable: "false",
  "data-prefix": "fa",
  "data-icon": "hotel",
  role: "img",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 576 512",
  class: "svg-inline--fa fa-hotel fa-w-18 fa-3x",
  style: {"color":"white","width":"20px !important","height":"20px !important"}
}
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_datetime_button = _resolveComponent("ion-datetime-button")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_DamageNoticeIcon = _resolveComponent("DamageNoticeIcon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper_container = _resolveComponent("swiper-container")!

  return (_ctx.data.housekeeping)
    ? (_openBlock(), _createBlock(_component_swiper_container, {
        key: 0,
        "slides-per-view": 1,
        "centered-slides": true,
        navigation: true
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.housekeeping, (housekeepingTask) => {
            return (_openBlock(), _createBlock(_component_swiper_slide, {
              key: housekeepingTask,
              style: _normalizeStyle(
                        _ctx.data.housekeeping.length > 1 ? 'padding: 10px 25px !important' : 'padding: 10px 0px !important'
                    )
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card, {
                  class: "ion-no-margin",
                  style: {"margin":"0px 15px !important"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, { style: {"display":"flex","align-items":"center","background":"linear-gradient(118deg, #0d47a1, rgba(13, 71, 161, 0.7))","padding":"10px"} }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          (_openBlock(), _createElementBlock("svg", _hoisted_2, _cache[0] || (_cache[0] = [
                            _createElementVNode("path", {
                              fill: "currentColor",
                              d: "M560 64c8.84 0 16-7.16 16-16V16c0-8.84-7.16-16-16-16H16C7.16 0 0 7.16 0 16v32c0 8.84 7.16 16 16 16h15.98v384H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h240v-80c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v80h240c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16h-16V64h16zm-304 44.8c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4zm0 96c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4zm-128-96c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4zM179.2 256h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4c0 6.4-6.4 12.8-12.8 12.8zM192 384c0-53.02 42.98-96 96-96s96 42.98 96 96H192zm256-140.8c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-96c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4z",
                              class: ""
                            }, null, -1)
                          ])))
                        ]),
                        _createVNode(_component_ion_label, { style: {"font-size":"18px","font-weight":"600","color":"white","margin-left":"10px"} }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(housekeepingTask.rental_property.name), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_card_content, { style: {"padding":"0px"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_list, { lines: "full" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_select, {
                                  label: "HOUSEKEEPER:",
                                  value: _ctx.assignedHousekeepers(housekeepingTask),
                                  onIonChange: ($event: any) => (_ctx.assignHousekeeper(housekeepingTask, $event.target.value))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_select_option, { value: null }, {
                                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                                        _createTextVNode("Not Assigned")
                                      ])),
                                      _: 1
                                    }),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(housekeepingTask.cleaner_options, (cleaner) => {
                                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                                        key: cleaner.id,
                                        value: cleaner.id
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(cleaner.full_name), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["value"]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1032, ["value", "onIonChange"])
                              ]),
                              _: 2
                            }, 1024),
                            (housekeepingTask.house_keeping_job)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                                          _createTextVNode("CLEAN ON:")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_datetime_button, {
                                        style: {"width":"fit-content"},
                                        datetime: "datetime"
                                      }),
                                      _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_datetime, {
                                            modelValue: housekeepingTask.house_keeping_job.schedule_dt,
                                            "onUpdate:modelValue": ($event: any) => ((housekeepingTask.house_keeping_job.schedule_dt) = $event),
                                            id: "datetime",
                                            presentation: "date",
                                            onIonChange: ($event: any) => (
                                                    _ctx.updateHousekeeper(
                                                        housekeepingTask,
                                                        'schedule_dt',
                                                        $event.target.value
                                                    )
                                                ),
                                            "show-default-buttons": true,
                                            min: _ctx.cleanOnDateMinimum()
                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onIonChange", "min"])
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_select, {
                                        label: "TASK:",
                                        value: housekeepingTask.house_keeping_job.job_type,
                                        onIonChange: ($event: any) => (
                                                _ctx.updateHousekeeper(housekeepingTask, 'job_type', $event.target.value)
                                            )
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(housekeepingTask.house_keeping_job.taskOptions, (task) => {
                                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                                              key: task.value,
                                              value: task.value
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(task.text), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["value"]))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1032, ["value", "onIonChange"])
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_select, {
                                        label: "STATUS:",
                                        value: housekeepingTask.house_keeping_job.job_status,
                                        onIonChange: ($event: any) => (
                                                _ctx.updateHousekeeper(housekeepingTask, 'job_status', $event.target.value)
                                            )
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(housekeepingTask.house_keeping_job.statusOptions, (option) => {
                                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                                              key: option.value,
                                              value: option.value
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(option.text), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["value"]))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1032, ["value", "onIonChange"])
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_ion_item, {
                                    onClick: ($event: any) => (_ctx.editInstructions(housekeepingTask)),
                                    style: {"cursor":"pointer"}
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _cache[3] || (_cache[3] = _createElementVNode("h3", null, "COMMENTS TO HOUSEKEEPER", -1)),
                                          _createElementVNode("p", null, _toDisplayString(housekeepingTask.house_keeping_job.instructions || 'N/A'), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_ion_icon, {
                                        icon: _ctx.pencil,
                                        slot: "end",
                                        style: {"width":"20px","height":"20px"}
                                      }, null, 8, ["icon"])
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]),
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _cache[4] || (_cache[4] = _createElementVNode("h3", null, "COMMENTS FROM HOUSEKEEPER", -1)),
                                          _createElementVNode("p", null, _toDisplayString(housekeepingTask.house_keeping_job.comments || 'N/A'), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  (housekeepingTask.house_keeping_job.damage_notice)
                                    ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_label, null, {
                                            default: _withCtx(() => [
                                              _cache[8] || (_cache[8] = _createElementVNode("h3", null, "DAMAGE NOTICE", -1)),
                                              _createElementVNode("p", null, [
                                                _cache[5] || (_cache[5] = _createElementVNode("span", { style: {"font-weight":"700"} }, "Description", -1)),
                                                _createTextVNode(": " + _toDisplayString(housekeepingTask.house_keeping_job.damage_notice.description), 1)
                                              ]),
                                              _createElementVNode("p", null, [
                                                _cache[6] || (_cache[6] = _createElementVNode("span", { style: {"font-weight":"700"} }, "Photos Reported", -1)),
                                                _createTextVNode(": " + _toDisplayString(housekeepingTask.house_keeping_job.damage_notice.photos.length), 1)
                                              ]),
                                              (housekeepingTask.house_keeping_job.damage_notice.work_order_id)
                                                ? (_openBlock(), _createElementBlock("p", _hoisted_3, _cache[7] || (_cache[7] = [
                                                    _createElementVNode("span", { style: {"font-weight":"700"} }, "Work Order Created", -1)
                                                  ])))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _: 2
                                          }, 1024),
                                          (housekeepingTask.house_keeping_job.damage_notice.work_order_id)
                                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                                key: 0,
                                                onClick: ($event: any) => (
                                                _ctx.openWorkOrder(
                                                    housekeepingTask.house_keeping_job.damage_notice.work_order_id
                                                )
                                            ),
                                                slot: "end",
                                                style: {"max-width":"40px","max-height":"40px","height":"40px","width":"40px","--background":"white"}
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_DamageNoticeIcon, { style: {"fill":"#0d47a1","width":"20px","height":"20px"} })
                                                ]),
                                                _: 2
                                              }, 1032, ["onClick"]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    : _createCommentVNode("", true)
                                ], 64))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["style"]))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}