import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/icons/vip.svg'
import _imports_1 from '@/assets/icons/blocklist.svg'


const _hoisted_1 = { style: {"display":"flex","align-items":"center","padding":"2px 0"} }
const _hoisted_2 = { style: {"margin-top":"2px"} }
const _hoisted_3 = { style: {"display":"flex","flex-direction":"column","width":"100%"} }
const _hoisted_4 = { style: {"padding":"0px","margin":"4px 0px","display":"flex","flex-direction":"row","align-items":"center"} }
const _hoisted_5 = {
  key: 0,
  style: {"display":"flex","align-items":"center"}
}
const _hoisted_6 = {
  key: 1,
  style: {"display":"flex","align-items":"center"}
}
const _hoisted_7 = { style: {"font-size":"16px","font-weight":"500"} }
const _hoisted_8 = { style: {"font-size":"14px","font-weight":"400","padding":"0px","margin":"0px 0px 5px"} }
const _hoisted_9 = { ref: "endOfGuestsList" }
const _hoisted_10 = { style: {"width":"100%","display":"flex","justify-content":"center","align-items":"center","margin":"10px 0px"} }
const _hoisted_11 = {
  key: 0,
  style: {"height":"55%","width":"100%","display":"flex","justify-content":"center","align-items":"center","flex-direction":"column"}
}
const _hoisted_12 = {
  key: 1,
  style: {"display":"flex","justify-content":"center","align-items":"center"}
}
const _hoisted_13 = { style: {"display":"flex","justify-content":"center","align-items":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, {
    "page-title": "View Guests",
    whiteBackground: "",
    removeMode: ""
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ion_searchbar, {
          placeholder: "Search Guests",
          debounce: 250,
          onIonInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.search($event))),
          style: {"padding":"0px 5px"}
        })
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_ion_label, { style: {"font-size":"larger"} }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.searchValue == '' ? 'Total' : 'Search Returned') + ": " + _toDisplayString(_ctx.totalGuests) + " Guests", 1)
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.guests, (guest, index) => {
          return (_openBlock(), _createBlock(_component_ion_list, {
            key: index,
            lines: "full",
            class: "ion-no-padding"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, {
                onClick: ($event: any) => (_ctx.ModalController.guestDetailsModal(guest.id)),
                style: {"cursor":"pointer"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      (guest.is_vip)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[1] || (_cache[1] = [
                            _createElementVNode("img", {
                              src: _imports_0,
                              class: "status-icon"
                            }, null, -1)
                          ])))
                        : (guest.is_blocklist)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[2] || (_cache[2] = [
                              _createElementVNode("img", {
                                src: _imports_1,
                                class: "status-icon"
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_7, _toDisplayString(guest.full_name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(guest.email), 1)
                  ]),
                  _createVNode(_component_ion_icon, {
                    slot: "end",
                    icon: _ctx.chevronForward
                  }, null, 8, ["icon"])
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_9, null, 512),
      _createElementVNode("div", _hoisted_10, [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_ion_spinner, {
              key: 0,
              color: "primary",
              name: "circles"
            }))
          : _createCommentVNode("", true)
      ]),
      (_ctx.guests.length <= 0 && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(_component_ion_icon, {
              icon: _ctx.people,
              style: {"width":"55px","height":"55px"},
              color: "medium"
            }, null, 8, ["icon"]),
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode("No Guests " + _toDisplayString(_ctx.searchValue != '' ? 'Found' : ''), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.guests.length >= _ctx.totalGuests && _ctx.guests.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("No More Guests")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}