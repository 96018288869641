<template>
    <modal-base-layout title="Vendors">
        <ion-list lines="full" style="padding-bottom: 60px" v-if="vendors.length > 0">
            <ion-item v-for="(vendor, index) in vendors" :key="index">
                <ion-label>
                    <h2>{{ vendor.id }}: {{ vendor.company }}</h2>
                    <p
                        class="truncate"
                        style="text-transform: uppercase; font-weight: 400; font-size: small; margin-bottom: 10px"
                    >
                        {{ vendor.specialty }}
                    </p>
                    <p
                        class="truncate"
                        style="display: flex; align-items: center"
                        v-if="vendor.first_name || vendor.last_name"
                    >
                        <ion-icon style="margin-right: 5px" :src="person" />{{ vendor.first_name }}
                        {{ vendor.last_name }}
                    </p>
                    <p class="truncate" style="display: flex; align-items: center" v-if="vendor.email">
                        <ion-icon style="margin-right: 5px" :src="mail" />{{ vendor.email }}
                    </p>
                    <p class="truncate" style="display: flex; align-items: center" v-if="vendor.mobile_phone">
                        <ion-icon style="margin-right: 5px" :src="call" />{{ vendor.mobile_phone }}
                    </p>
                </ion-label>
            </ion-item>
        </ion-list>

        <div
            v-else
            style="
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: center;
                align-items: center;
                height: 50vh;
            "
        >
            <ion-icon :src="personCircle" style="width: 50px; height: 50px; margin-bottom: 10px" />
            <ion-label style="font-size: large">No Vendors Found</ion-label>
        </div>

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="medium" @click="cancel()">Close</ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { alertController, modalController } from '@ionic/vue';
    import {
        arrowBackOutline,
        add,
        refresh,
        create,
        trashBin,
        downloadOutline,
        trash,
        pencil,
        call,
        mail,
        person,
        personCircle,
    } from 'ionicons/icons';
    import { defineComponent, onMounted, ref, watch } from 'vue';
    import { useStore } from '@/store/store';
    import AppAPI from '@/services/AppAPI';
    import { hideLoading, showLoading } from '@/services/Loading';
    import { errorToast, successToast } from '@/services/Toast';
    import { advancedAlert, basicAlert } from '@/services/AlertController';

    export default defineComponent({
        components: {},
        setup() {
            const store = useStore();
            const vendors = ref([] as any[]);

            const cancel = () => {
                return modalController.dismiss(null, 'cancel');
            };

            const confirm = async () => {
                return modalController.dismiss({}, 'confirm');
            };

            const refresh = async () => {
                const loading = await showLoading('Refreshing vendors...');
                try {
                    const data = await AppAPI.getWorkOrderVendors();
                    vendors.value = data.data;
                } catch (error) {
                    errorToast('Error refreshing vendors list');
                } finally {
                    hideLoading(loading);
                }
            };

            onMounted(async () => {
                await refresh();
            });

            return {
                arrowBackOutline,
                store,
                add,
                trashBin,
                downloadOutline,
                trash,
                call,
                mail,
                pencil,
                person,
                vendors,
                AppAPI,
                personCircle,
                refresh,
                cancel,
                confirm,
            };
        },
    });
</script>

<style scoped>
    ion-item {
        --background: transparent;
    }

    ion-list {
        background: transparent;
    }

    .truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: fit-content;
    }
</style>
