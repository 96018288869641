<template>
    <ion-list lines="full">
        <ion-item>
            <ion-label>Name</ion-label>
            <ion-label text-wrap slot="end" style="text-align: end">{{ data.guest?.full_name }}</ion-label>
            <img
                slot="end"
                style="padding-right: 0px; margin-left: 10px"
                v-if="data.guest?.is_vip"
                src="@/assets/icons/vip.svg"
            />
            <img
                slot="end"
                style="padding-right: 0px; margin-left: 10px"
                v-if="data.guest?.is_blocklist"
                src="@/assets/icons/blocklist.svg"
            />
        </ion-item>
        <ion-item v-if="data.guest?.phone">
            <ion-label>Email</ion-label>
            <ion-label text-wrap slot="end" style="text-align: end">{{ data.guest?.email }}</ion-label>
            <ion-icon
                slot="end"
                style="margin-left: 10px; cursor: pointer"
                @click="AppFunctions.openEmail(data.guest?.email)"
                :icon="mailOutline"
            >
            </ion-icon>
        </ion-item>
        <ion-item v-else style="border-bottom: none">
            <ion-label>Email</ion-label>
            <ion-label text-wrap slot="end" style="text-align: end">{{ data.guest?.email }}</ion-label>
            <ion-icon
                slot="end"
                style="margin-left: 10px; cursor: pointer"
                @click="AppFunctions.openEmail(data.guest?.email)"
                :icon="mailOutline"
            >
            </ion-icon>
        </ion-item>
        <ion-item v-if="data.guest?.phone" style="border-bottom: none">
            <ion-label>Phone</ion-label>
            <ion-label text-wrap slot="end" style="text-align: end">{{ data.guest?.phone }}</ion-label>
            <ion-icon
                slot="end"
                style="margin-left: 10px; cursor: pointer"
                @click="AppFunctions.openPhone(data.guest?.phone)"
                :icon="callOutline"
            >
            </ion-icon>
            <ion-icon
                slot="end"
                style="margin-left: 10px; cursor: pointer"
                @click="AppFunctions.openText(data.guest?.phone)"
                :icon="chatboxEllipsesOutline"
            >
            </ion-icon>
        </ion-item>

        <ion-item v-if="data.guest">
            <ion-label>Address</ion-label>
            <div class="address" slot="end">
                <p v-if="data.guest.address.address1">{{ data.guest.address.address1 }}</p>
                <p v-if="data.guest.address.address2">{{ data.guest.address.address2 }}</p>
                <p v-if="data.guest.address.city || data.guest.address.state?.code || data.guest.address.zip">
                    {{ data.guest.address.city }}, {{ data.guest.address.state?.code || '' }}
                    {{ data.guest.address.zip }}
                </p>
                <p>{{ data.guest.address.country?.name || '' }}</p>
            </div>
        </ion-item>
    </ion-list>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { chatboxEllipsesOutline, callOutline, mailOutline } from 'ionicons/icons';
    import AppFunctions from '@/services/AppFunctions';

    export default defineComponent({
        props: {
            data: {
                type: Object,
                required: true,
            },
        },
        emits: [''],
        setup(props, ctx) {
            return {
                chatboxEllipsesOutline,
                mailOutline,
                callOutline,
                AppFunctions,
            };
        },
    });
</script>
