<template>
    <modal-base-layout
        :title="workOrderId ? 'Work Order #' + workOrderDetails?.number : 'Create Work Order'"
        background="rgb(239, 246, 255)"
    >
        <ion-list lines="full" v-if="workOrderDetails">
            <div class="card">
                <ion-item>
                    <ion-select v-model="status" placeholder="Not Set" label="Status">
                        <template v-for="status in store.workOrderFormOptions.status_choices" :key="status.id">
                            <ion-select-option
                                :value="status.id"
                                v-if="
                                    (status.id == 221 && !store.workOrderPermissions.is_close_disabled) ||
                                    status.id != 221
                                "
                                >{{ status.name }}</ion-select-option
                            >
                        </template>
                    </ion-select>
                </ion-item>
                <ion-item>
                    <ion-select v-model="property" placeholder="Not Set" label="Property">
                        <template v-for="property in store.workOrderFormProperties" :key="property.id">
                            <ion-select-option :value="property.id">{{ property.name }}</ion-select-option>
                        </template>
                    </ion-select>
                </ion-item>

                <ion-item :lines="reporterType && reporterType != 'SUBSCRIBER' ? 'none' : 'full'">
                    <ion-select v-model="reporterType" label="Reported By">
                        <ion-select-option value="SUBSCRIBER">Subscriber</ion-select-option>
                        <ion-select-option value="TEAM_MEMBER">Team Member</ion-select-option>
                        <ion-select-option v-if="workOrderOwners?.length > 0" value="OWNER">Owner</ion-select-option>
                        <ion-select-option value="OTHER">Other</ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-item v-if="reporterType && reporterType != 'SUBSCRIBER'">
                    <ion-select placeholder="" v-model="reporter" v-if="reporterType == 'TEAM_MEMBER'" label="">
                        <template v-for="member in store.WorkOrderTeamMembers" :key="member.id">
                            <ion-select-option :value="member.id">{{
                                member.first_name + ' ' + member.last_name
                            }}</ion-select-option>
                        </template>
                    </ion-select>
                    <ion-select placeholder="" v-model="reporter" v-if="reporterType == 'OWNER'" label="">
                        <template v-for="owner in workOrderOwners" :key="owner.id">
                            <ion-select-option :value="owner.id">{{
                                owner.first_name + ' ' + owner.last_name
                            }}</ion-select-option>
                        </template>
                    </ion-select>
                    <ion-input
                        placeholder="Reported By Email"
                        label="Email"
                        labelPlacement="floating"
                        v-model="reporter"
                        v-if="reporterType == 'OTHER'"
                    ></ion-input>
                </ion-item>

                <div
                    v-if="false"
                    style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        border-bottom: 0.25px solid rgb(196, 195, 195);
                    "
                >
                    <ion-label style="width: 100%; margin-left: 15px">Reported By</ion-label>
                    <ion-select style="width: fit-content; margin-right: 15px" v-model="reporterType" label="">
                        <ion-select-option value="SUBSCRIBER">Subscriber</ion-select-option>
                        <ion-select-option value="TEAM_MEMBER">Team Member</ion-select-option>
                        <ion-select-option v-if="workOrderOwners?.length > 0" value="OWNER">Owner</ion-select-option>
                        <ion-select-option value="OTHER">Other</ion-select-option>
                    </ion-select>
                    <ion-select
                        style="width: fit-content; margin-right: 15px"
                        placeholder=""
                        v-model="reporter"
                        v-if="reporterType == 'TEAM_MEMBER'"
                        label=""
                    >
                        <template v-for="member in store.WorkOrderTeamMembers" :key="member.id">
                            <ion-select-option :value="member.id">{{
                                member.first_name + ' ' + member.last_name
                            }}</ion-select-option>
                        </template>
                    </ion-select>
                    <ion-select
                        style="width: fit-content; margin-right: 15px"
                        placeholder=""
                        v-model="reporter"
                        v-if="reporterType == 'OWNER'"
                        label=""
                    >
                        <template v-for="owner in workOrderOwners" :key="owner.id">
                            <ion-select-option :value="owner.id">{{
                                owner.first_name + ' ' + owner.last_name
                            }}</ion-select-option>
                        </template>
                    </ion-select>
                    <ion-input
                        style="width: fit-content; margin-right: 15px"
                        placeholder="Enter Email Here"
                        v-model="reporter"
                        v-if="reporterType == 'OTHER'"
                    ></ion-input>
                </div>
                <ion-item>
                    <ion-select v-model="accountAssignee" placeholder="Not Set" label="Who Will Pay">
                        <ion-select-option :value="workOrderDetails.subscriber || 'SUBSCRIBER'"
                            >Subscriber</ion-select-option
                        >
                        <template v-for="owner in workOrderOwners" :key="owner.id">
                            <ion-select-option :value="owner.id">{{
                                owner.first_name + ' ' + owner.last_name
                            }}</ion-select-option>
                        </template>
                    </ion-select>
                </ion-item>

                <ion-item :lines="damageNoticeSource ? 'full' : 'none'">
                    <ion-select label="Account" v-model="account">
                        <template v-for="expenseAccount in store.WorkOrderExpenseAccoutns" :key="expenseAccount">
                            <ion-select-option :value="expenseAccount.id"
                                >{{ expenseAccount.number }} - {{ expenseAccount.name }}</ion-select-option
                            >
                        </template>
                    </ion-select>
                </ion-item>

                <ion-item lines="none" v-if="damageNoticeSource && reservationAccess()">
                    <ion-label style="font-family: 'San Francisco', sans-serif; font-size: 15px; font-weight: 500"
                        >Source</ion-label
                    >
                    <ion-button style="padding: 10px 0" slot="end" @click="openInvoice()">
                        Damage Notice - Inv #{{ damageNoticeSource.meta_reservation_id }}
                    </ion-button>
                </ion-item>
            </div>

            <div class="card">
                <ion-segment
                    v-model="segmentValue"
                    mode="ios"
                    style="border-radius: 8px 8px 0 0; height: 50px"
                    scrollable
                >
                    <ion-segment-button value="problem">
                        <ion-label>Problem</ion-label>
                    </ion-segment-button>
                    <ion-segment-button value="materials-and-expenses">
                        <ion-label>Materials & Expenses</ion-label>
                    </ion-segment-button>

                    <ion-segment-button value="internal-notes">
                        <ion-label>Internal Notes</ion-label>
                    </ion-segment-button>
                </ion-segment>

                <div v-if="segmentValue == 'problem'">
                    <!-- Problem tab items -->
                    <ion-item>
                        <ion-select label="Job Type" v-model="jobType" placeholder="Not Set">
                            <template v-for="jobType in store.workOrderJobTypes" :key="jobType.id">
                                <ion-select-option :value="jobType.id">{{ jobType.title }}</ion-select-option>
                            </template>
                        </ion-select>
                    </ion-item>
                    <ion-item>
                        <ion-select label="Priority" v-model="priority" placeholder="Not Set">
                            <template
                                v-for="priority in store.workOrderFormOptions.priority_choices"
                                :key="priority.id"
                            >
                                <ion-select-option :value="priority.id">{{ priority.name }}</ion-select-option>
                            </template>
                        </ion-select>
                    </ion-item>
                    <ion-item lines="full">
                        <ion-label style="font-family: 'San Francisco', sans-serif; font-size: 15px; font-weight: 500"
                            >Date Reported</ion-label
                        >
                        <ion-datetime-button datetime="dateReported"></ion-datetime-button>
                        <ion-modal :keep-contents-mounted="true">
                            <ion-datetime
                                id="dateReported"
                                presentation="date"
                                v-model="workOrderDetails.date_reported"
                                :format-options="formatOptions"
                                :show-default-buttons="true"
                            ></ion-datetime>
                        </ion-modal>
                    </ion-item>
                    <ion-item>
                        <ion-textarea
                            autoGrow
                            style="margin-bottom: 10px"
                            v-model="workOrderDescription"
                            placeholder="No Description Added"
                            label="Description"
                            labelPlacement="floating"
                        ></ion-textarea>
                    </ion-item>
                    <ion-item lines="full">
                        <ion-label style="font-family: 'San Francisco', sans-serif; font-size: 15px; font-weight: 500"
                            >Start Date</ion-label
                        >
                        <div slot="end" v-if="workOrderDetails.start_date">
                            <ion-datetime-button datetime="dateStarted"></ion-datetime-button>
                            <ion-modal :keep-contents-mounted="true">
                                <ion-datetime
                                    id="dateStarted"
                                    presentation="date"
                                    v-model="workOrderDetails.start_date"
                                    :format-options="formatOptions"
                                    :show-default-buttons="true"
                                ></ion-datetime>
                            </ion-modal>
                        </div>
                        <ion-button
                            color="primary"
                            style="padding: 10px 0"
                            v-else
                            slot="end"
                            @click="workOrderDetails.start_date = getTodayDate()"
                            >Start Task</ion-button
                        >
                    </ion-item>
                    <ion-item lines="full">
                        <ion-label style="font-family: 'San Francisco', sans-serif; font-size: 15px; font-weight: 500"
                            >Completed Date</ion-label
                        >
                        <div slot="end" v-if="workOrderDetails.completed_date">
                            <ion-datetime-button datetime="dateCompleted"></ion-datetime-button>
                            <ion-modal :keep-contents-mounted="true">
                                <ion-datetime
                                    id="dateCompleted"
                                    presentation="date"
                                    v-model="workOrderDetails.completed_date"
                                    :format-options="formatOptions"
                                    :show-default-buttons="true"
                                ></ion-datetime>
                            </ion-modal>
                        </div>
                        <ion-button
                            style="padding: 10px 0"
                            color="primary"
                            v-else
                            slot="end"
                            @click="workOrderDetails.completed_date = getTodayDate()"
                            >Mark Complete</ion-button
                        >
                    </ion-item>

                    <div v-if="workOrderDetails.completed_date">
                        <ion-item lines="full">
                            <ion-label
                                style="font-family: 'San Francisco', sans-serif; font-size: 15px; font-weight: 500"
                                >Amount</ion-label
                            >
                            <CurrencyInput
                                v-model="workOrderDetails.amount"
                                :options="{ currency: store.userCurrency }"
                                placeholder="Enter Amount"
                            />
                        </ion-item>

                        <ion-item lines="none">
                            <ion-input
                                labelPlacement="start"
                                placeholder="Enter Invoice #"
                                style="text-align: end"
                                label="Vendor Invoice #"
                                v-model="vendorInvoiceNumber"
                                @input="vendorInvoiceNumberCheck"
                            ></ion-input>
                        </ion-item>
                        <ion-item>
                            <ion-select placeholder="Not Set" v-model="vendorInvoiceVendorId" label="">
                                <ion-select-option value="">Select Vendor</ion-select-option>
                                <template v-for="vendor in store.workOrderVendors" :key="vendor.id">
                                    <ion-select-option :value="vendor.id">{{ vendor.company }}</ion-select-option>
                                </template>
                            </ion-select>
                        </ion-item>

                        <ion-item lines="none">
                            <ion-input
                                labelPlacement="start"
                                placeholder="Enter Receipt #"
                                style="text-align: end"
                                label="Store Receipt #"
                                v-model="receiptNumber"
                                @input="receiptNumberCheck"
                            ></ion-input>
                        </ion-item>
                        <ion-item>
                            <ion-select placeholder="Not Set" v-model="receiptStoreId" label="">
                                <ion-select-option value="">Select Vendor</ion-select-option>
                                <template v-for="vendor in store.workOrderVendors" :key="vendor.id">
                                    <ion-select-option :value="vendor.id">{{ vendor.company }}</ion-select-option>
                                </template>
                            </ion-select>
                        </ion-item>
                    </div>

                    <ion-item :lines="assigneeType ? 'none' : 'full'">
                        <ion-select v-model="assigneeType" label="Assign To" placeholder="Not Set">
                            <ion-select-option value="">Not Selected</ion-select-option>
                            <ion-select-option value="TEAM_MEMBER">Team Member</ion-select-option>
                            <ion-select-option v-if="workOrderOwners?.length > 0" value="OWNER"
                                >Owner</ion-select-option
                            >
                            <ion-select-option v-if="store.workOrderVendors?.length > 0" value="VENDOR"
                                >Vendor</ion-select-option
                            >
                        </ion-select>
                    </ion-item>
                    <ion-item v-if="assigneeType">
                        <ion-select
                            placeholder="Select Assignee"
                            v-model="assignee"
                            v-if="assigneeType == 'TEAM_MEMBER'"
                            label=""
                        >
                            <template v-for="member in store.WorkOrderTeamMembers" :key="member.id">
                                <ion-select-option :value="member.id">{{
                                    member.first_name + ' ' + member.last_name
                                }}</ion-select-option>
                            </template>
                        </ion-select>
                        <ion-select
                            placeholder="Select Assignee"
                            v-model="assignee"
                            v-if="assigneeType == 'OWNER'"
                            label=""
                        >
                            <template v-for="owner in workOrderOwners" :key="owner.id">
                                <ion-select-option :value="owner.id">{{
                                    owner.first_name + ' ' + owner.last_name
                                }}</ion-select-option>
                            </template>
                        </ion-select>
                        <ion-select
                            placeholder="Select Assignee"
                            v-model="assignee"
                            v-if="assigneeType == 'VENDOR'"
                            label=""
                        >
                            <template v-for="vendor in store.workOrderVendors" :key="vendor.id">
                                <ion-select-option :value="vendor.id">{{ vendor.company }}</ion-select-option>
                            </template>
                        </ion-select>
                    </ion-item>

                    <ion-item v-if="assignee" :lines="alsoNotifyType ? 'none' : 'full'">
                        <ion-select v-model="alsoNotifyType" label="Also Notify" placeholder="Not Set">
                            <ion-select-option value="">Not Selected</ion-select-option>
                            <ion-select-option value="TEAM_MEMBER">Team Member</ion-select-option>
                            <ion-select-option v-if="workOrderOwners?.length > 0" value="OWNER"
                                >Owner</ion-select-option
                            >
                            <ion-select-option v-if="store.workOrderVendors?.length > 0" value="VENDOR"
                                >Vendor</ion-select-option
                            >
                        </ion-select>
                    </ion-item>
                    <ion-item v-if="alsoNotifyType">
                        <ion-select
                            placeholder="Select User to Notify"
                            v-model="alsoNotify"
                            v-if="alsoNotifyType == 'TEAM_MEMBER'"
                            label=""
                        >
                            <template v-for="member in store.WorkOrderTeamMembers" :key="member.id">
                                <ion-select-option v-if="member.id != assignee" :value="member.id">{{
                                    member.first_name + ' ' + member.last_name
                                }}</ion-select-option>
                            </template>
                        </ion-select>
                        <ion-select
                            placeholder="Select User to Notify"
                            v-model="alsoNotify"
                            v-if="alsoNotifyType == 'OWNER'"
                            label=""
                        >
                            <template v-for="owner in workOrderOwners" :key="owner.id">
                                <ion-select-option v-if="owner.id != assignee" :value="owner.id">{{
                                    owner.first_name + ' ' + owner.last_name
                                }}</ion-select-option>
                            </template>
                        </ion-select>
                        <ion-select
                            placeholder="Select User to Notify"
                            v-model="alsoNotify"
                            v-if="alsoNotifyType == 'VENDOR'"
                            label=""
                        >
                            <template v-for="vendor in store.workOrderVendors" :key="vendor.id">
                                <ion-select-option v-if="vendor.id != assignee" :value="vendor.id">{{
                                    vendor.company
                                }}</ion-select-option>
                            </template>
                        </ion-select>
                    </ion-item>

                    <ion-item v-if="assignee">
                        <ion-checkbox
                            v-model="workOrderDetails.email_details"
                            justify="space-between"
                            style="font-family: 'San Francisco', sans-serif; font-size: 15px; font-weight: 500"
                            >Email Work Order Details</ion-checkbox
                        >
                    </ion-item>

                    <ion-item>
                        <ion-textarea
                            autoGrow
                            style="margin-bottom: 10px"
                            v-model="workOrderDetails.notes"
                            placeholder="Enter Notes Here..."
                            label="Notes"
                            labelPlacement="floating"
                        ></ion-textarea>
                    </ion-item>
                    <div>
                        <ion-item v-if="workOrderDetails.photos || photos.length" lines="none">
                            <ion-label
                                style="font-family: 'San Francisco', sans-serif; font-size: 15px; font-weight: 500"
                                >Photos</ion-label
                            >
                            <ion-button
                                v-if="store.workOrderPermissions.has_modify_access"
                                @click="uploadPhoto()"
                                style="aspect-ratio: 1/1; height: 44px; padding: 2px 0"
                                color="primary"
                                ><ion-icon :src="add"></ion-icon
                            ></ion-button>
                        </ion-item>
                        <swiper-container
                            v-if="(workOrderDetails.photos.length || photos.length) && isShowPhotos"
                            class="photos"
                            :slides-per-view="'1.5'"
                            style="padding: 10px 0"
                        >
                            <template v-for="(item, index) in workOrderImages" :key="index">
                                <swiper-slide>
                                    <div style="max-width: 400px; height: fit-content; margin: 0 10px">
                                        <div style="position: relative">
                                            <ion-button
                                                v-if="store.workOrderPermissions.has_modify_access && !item.deleted"
                                                @click="downloadPhoto(item.id)"
                                                color="light"
                                                style="
                                                    position: absolute;
                                                    top: 0;
                                                    left: 0;
                                                    margin: 0px;
                                                    height: 25px;
                                                    aspect-ratio: 1/1;
                                                    --border-radius: 10px 0 10px 0;
                                                "
                                                ><ion-icon :src="downloadOutline" style="height: 25px"
                                            /></ion-button>
                                            <ion-button
                                                v-if="store.workOrderPermissions.has_modify_access && !item.deleted"
                                                @click="deletePhoto(item.id, true)"
                                                color="danger"
                                                style="
                                                    position: absolute;
                                                    top: 0;
                                                    right: 0;
                                                    margin: 0px;
                                                    height: 25px;
                                                    aspect-ratio: 1/1;
                                                    --border-radius: 0 10px 0 10px;
                                                "
                                                ><ion-icon :src="trash" style="height: 25px"
                                            /></ion-button>
                                            <ion-icon
                                                @click="updateCaption(item.id, true)"
                                                v-if="store.workOrderPermissions.has_add_access && item.deleted"
                                                style="
                                                    position: absolute;
                                                    top: 50%;
                                                    left: 50%;
                                                    transform: translate(-50%, -50%);
                                                    z-index: 99 !important;
                                                    width: 25%;
                                                    height: 25%;
                                                "
                                                :src="refresh"
                                            ></ion-icon>
                                            <img
                                                :src="item.image || 'assets/img/no_image.png'"
                                                @click="updateCaption(item.id, true)"
                                                style="
                                                    display: flex;
                                                    object-fit: contain;
                                                    border: 1px solid lightgray;
                                                    border-radius: 10px;
                                                    width: 100%;
                                                "
                                                :style="[
                                                    !item.caption
                                                        ? ''
                                                        : 'border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;',
                                                    item.deleted ? 'filter: grayscale(100%);' : '',
                                                ]"
                                            />
                                        </div>
                                        <div
                                            v-if="item.caption"
                                            style="
                                                background-color: white;
                                                border: 1px solid lightgray;
                                                border-top: none;
                                                border-bottom-left-radius: 10px;
                                                border-bottom-right-radius: 10px;
                                                padding: 5px;
                                            "
                                        >
                                            {{ item.caption }}
                                        </div>
                                    </div>
                                </swiper-slide>
                            </template>
                            <template v-for="(item, index) in photos" :key="index">
                                <swiper-slide>
                                    <div style="max-width: 400px; height: fit-content; margin: 0 10px">
                                        <div style="position: relative">
                                            <ion-button
                                                v-if="store.workOrderPermissions.has_modify_access"
                                                @click="deletePhoto(index, false)"
                                                color="danger"
                                                style="
                                                    position: absolute;
                                                    top: 0;
                                                    right: 0;
                                                    margin: 0px;
                                                    height: 25px;
                                                    aspect-ratio: 1/1;
                                                    --border-radius: 0 10px 0 10px;
                                                "
                                                ><ion-icon :src="trash" style="height: 25px"
                                            /></ion-button>
                                            <ion-icon
                                                @click="updateCaption(item.id, false)"
                                                v-if="store.workOrderPermissions.has_add_access && item.deleted"
                                                style="
                                                    position: absolute;
                                                    top: 50%;
                                                    left: 50%;
                                                    transform: translate(-50%, -50%);
                                                    z-index: 99 !important;
                                                    width: 25%;
                                                    height: 25%;
                                                "
                                                :src="refresh"
                                            ></ion-icon>
                                            <img
                                                :src="item.photo_url"
                                                @click="updateCaption(index, false)"
                                                style="
                                                    display: flex;
                                                    object-fit: contain;
                                                    border: 1px solid lightgray;
                                                    border-radius: 10px;
                                                    width: 100%;
                                                "
                                                :style="[
                                                    !item.caption
                                                        ? ''
                                                        : 'border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;',
                                                    item.deleted ? 'filter: grayscale(100%);' : '',
                                                ]"
                                            />
                                        </div>
                                        <div
                                            v-if="item.caption"
                                            style="
                                                background-color: white;
                                                border: 1px solid lightgray;
                                                border-top: none;
                                                border-bottom-left-radius: 10px;
                                                border-bottom-right-radius: 10px;
                                                padding: 5px;
                                            "
                                        >
                                            {{ item.caption }}
                                        </div>
                                    </div>
                                </swiper-slide>
                            </template>
                        </swiper-container>

                        <ion-item v-else lines="none">
                            <ion-label style="margin: 0px; margin-bottom: 10px; font-style: italic"
                                >No Photos Added</ion-label
                            >
                        </ion-item>
                    </div>
                </div>

                <!-- Materials and Expenses item -->
                <div v-if="segmentValue == 'materials-and-expenses'">
                    <ion-item>
                        <ion-button
                            v-if="!store.workOrderPermissions.is_modify_disabled && workOrderId"
                            style="--border-radius: 10px; aspect-ratio: 1/1; height: 30px; margin: 0 2px"
                            color="primary"
                            slot="end"
                            @click="loadMaterialsExpenses"
                        >
                            <ion-icon :src="refresh"></ion-icon>
                        </ion-button>
                        <ion-button
                            v-if="!store.workOrderPermissions.is_modify_disabled"
                            style="--border-radius: 10px; aspect-ratio: 1/1; height: 30px; margin: 0 2px"
                            color="primary"
                            slot="end"
                            @click="openCreateMaterialExpenseModal"
                            ><ion-icon :src="add"></ion-icon
                        ></ion-button>
                    </ion-item>
                    <ion-item
                        v-for="(materialExpense, index) in materialsExpenses || materials"
                        :key="materialExpense.id"
                    >
                        <div style="display: flex; flex-direction: column; padding: 10px; width: 100%">
                            <ion-label style="margin: 5px 0">{{ formatDate(materialExpense.date) }}</ion-label>
                            <ion-label
                                style="
                                    margin: 5px 0;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                "
                                :style="[materialExpense.description ? '' : 'color:#a9a9a9']"
                                >{{
                                    materialExpense.description
                                        ? 'Description: ' + materialExpense.description
                                        : 'No Description'
                                }}</ion-label
                            >
                            <ion-label style="margin: 5px 0"
                                >Qty: {{ materialExpense.qty }} Price: {{ materialExpense.price }}</ion-label
                            >
                        </div>

                        <div slot="end" style="display: flex; flex-direction: column">
                            <ion-button
                                v-if="!store.workOrderPermissions.is_modify_disabled"
                                style="--border-radius: 10px; width: 100%; padding: 2px"
                                color="primary"
                                @click="editCreateMaterialExpenseModal(materialExpense)"
                            >
                                <ion-icon :src="create"></ion-icon>
                            </ion-button>
                            <ion-button
                                v-if="!store.workOrderPermissions.is_modify_disabled"
                                style="--border-radius: 10px; width: 100%; padding: 2px"
                                color="danger"
                                @click="deleteMaterialExpense(materialExpense.id || index)"
                            >
                                <ion-icon :src="trashBin"></ion-icon>
                            </ion-button>
                        </div>
                    </ion-item>
                    <ion-item v-if="(!materialsExpenses || materialsExpenses.length <= 0) && materials.length <= 0">
                        <ion-label style="margin: 15px; font-style: italic; font-weight: 400; text-align: center"
                            >No Materials and Expenses Added</ion-label
                        >
                    </ion-item>
                </div>

                <!-- Internal Notes item -->
                <div v-if="segmentValue == 'internal-notes'">
                    <ion-textarea
                        autoGrow
                        style="padding: 5px 15px 10px"
                        v-model="workOrderDetails.vendor_team_member_notes"
                        placeholder="Enter Internal Notes Here..."
                        label="Internal Notes"
                        labelPlacement="stacked"
                        fill="outline"
                        mode="ios"
                    ></ion-textarea>
                </div>
            </div>
        </ion-list>
        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="medium" @click="cancel()">Cancel</ion-button>
                    </ion-col>
                    <ion-col>
                        <ion-button
                            v-if="!store.workOrderPermissions.is_modify_disabled && workOrderId"
                            class="modal-footer-button"
                            color="primary"
                            @click="confirm()"
                            >Save</ion-button
                        >
                        <ion-button
                            v-if="store.workOrderPermissions.has_add_access && !workOrderId"
                            class="modal-footer-button"
                            color="primary"
                            @click="createWorkOrder()"
                            >Create</ion-button
                        >
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController } from '@ionic/vue';
    import { arrowBackOutline, add, refresh, create, trashBin, downloadOutline, trash } from 'ionicons/icons';
    import { defineComponent, onMounted, ref, watch } from 'vue';
    import { format } from 'date-fns';
    import { useStore } from '@/store/store';
    import AppAPI from '@/services/AppAPI';
    import { hideLoading, showLoading } from '@/services/Loading';
    import ModalController from '@/services/ModalController';
    import { accessToken } from '@/services/OAuth2';
    import { Camera, CameraResultType } from '@capacitor/camera';
    import { getDataURL, getImageResolution } from '@/utils/Image';
    import { advancedAlert, basicInputAlert } from '@/services/AlertController';
    import { errorToast, infoToast } from '@/services/Toast';
    import CurrencyInput from '@/components/CurrencyInput.vue';
    //import { Share } from '@capacitor/share';
    import { Capacitor } from '@capacitor/core';
    import AppFunctions from '@/services/AppFunctions';
    import { reservationAccess } from '@/types/accessGroups';

    export default defineComponent({
        components: {
            CurrencyInput,
        },
        props: {
            workOrderId: {
                type: String,
                required: true,
            },
        },
        setup(props) {
            const store = useStore();
            const segmentValue = ref('problem');

            const materialsExpenses = ref();

            const status = ref();
            const property = ref();
            const reporterType = ref();
            const reporter = ref();
            const accountAssignee = ref();
            const account = ref();
            const jobType = ref();
            const priority = ref();

            const workOrderImages = ref();
            const workOrderImagesToDelete = ref([] as any[]);
            const workOrderDetails = ref();
            const workOrderDescription = ref();
            const workOrderOwners = ref();
            const assignee = ref();
            const assigneeType = ref();
            const alsoNotify = ref();
            const alsoNotifyType = ref();

            const vendorInvoiceNumber = ref();
            const vendorInvoiceVendorId = ref();
            const receiptNumber = ref();
            const receiptStoreId = ref();

            const materials = ref([] as any[]);

            const photos = ref([] as any[]);
            const isShowPhotos = ref(true);

            const damageNoticeSource = ref();

            const formatOptions = {
                date: {
                    weekday: 'long',
                    month: 'long',
                    day: '2-digit',
                },
            };

            const cancel = () => {
                return modalController.dismiss(null, 'cancel');
            };

            const confirm = async () => {
                let alt_rental_property =
                    store.WorkOrderFormProperties.find((storeProperty) => storeProperty.id == property.value)?.name ||
                    '';
                if (alt_rental_property == '') {
                    return errorToast('Please select a property');
                }

                if (!workOrderDetails.value.amount) {
                    workOrderDetails.value.amount = 0;
                }

                const loadingService = await showLoading('Saving...');

                try {
                    const data = {
                        id: workOrderDetails.value.id, //done
                        number: workOrderDetails.value.number, //done, cannot be edited in app so it should be done.
                        status: status.value, //done
                        property_or_room: property.value, //done
                        alt_rental_property: alt_rental_property, // done
                        reported_by_type: reporterType.value, //done
                        reporter_team_member: reporterType.value == 'TEAM_MEMBER' ? reporter.value : '', //done
                        reporter_owner: reporterType.value == 'OWNER' ? reporter.value : '', //done
                        alt_reporter: reporterType.value == 'OTHER' ? reporter.value : '', //done
                        account_assignee: accountAssignee.value, // done
                        account: account.value, // done
                        job_type: jobType.value, // done
                        priority: priority.value, // done
                        date_reported: workOrderDetails.value.date_reported, // done
                        description: workOrderDescription.value, // done
                        start_date: workOrderDetails.value.start_date, // done
                        completed_date: workOrderDetails.value.completed_date, // done
                        amount: workOrderDetails.value.amount || 0, // done

                        invoice_number: vendorInvoiceNumber.value || '', // done
                        receipt_number: receiptNumber.value || '', // done
                        invoice_vendor: vendorInvoiceVendorId.value || '', // done
                        receipt_store: receiptStoreId.value || '', // done

                        assigned_to: assigneeType.value || '', // done
                        team_member: assigneeType.value != 'VENDOR' ? assignee.value || '' : '', // done
                        team_member_type: assigneeType.value != 'VENDOR' ? assignee.value : '', // done
                        vendor: assigneeType.value == 'VENDOR' ? assignee.value || '' : '', // done

                        also_notify: alsoNotifyType.value || '', // done
                        also_notify_team_member: alsoNotifyType.value != 'VENDOR' ? alsoNotify.value || '' : '', // done
                        also_notify_vendor: alsoNotifyType.value == 'VENDOR' ? alsoNotify.value || '' : '', // done

                        email_details: workOrderDetails.value.email_details, // done
                        notes: workOrderDetails.value.notes, // done
                        vendor_team_member_notes: workOrderDetails.value.vendor_team_member_notes, // done
                    } as any;

                    let formData = new FormData();
                    for (let key in data) {
                        if (data[key] != null) {
                            formData.append(key, data[key]);
                        }
                    }

                    if (photos.value && photos.value.length > 0) {
                        photos.value.forEach((photo) => {
                            formData.append('photos_to_create', photo.file);
                            formData.append('photo_captions_to_create', photo.caption);
                        });
                    }

                    if (workOrderImagesToDelete.value && workOrderImagesToDelete.value.length > 0) {
                        workOrderImagesToDelete.value.forEach((image: any) => {
                            formData.append('photo_ids_to_delete', image.id);
                        });
                    }

                    if (workOrderImages.value && workOrderImages.value.length > 0) {
                        workOrderImages.value.forEach((image: any) => {
                            if (!workOrderImagesToDelete.value.find((img: any) => img.id == image.id)) {
                                formData.append('photo_ids_to_update', image.id);
                                formData.append('photo_captions_to_update', image.caption);
                            }
                        });
                    }

                    const response = await AppAPI.updateWorkOrder(formData);
                    hideLoading(loadingService);
                } catch (error) {
                    hideLoading(loadingService);
                    alert('error');
                    alert(error);
                    //console.log('error', error);
                }
                return modalController.dismiss({}, 'confirm');
            };

            const createWorkOrder = async () => {
                if (!workOrderDetails.value.amount) {
                    workOrderDetails.value.amount = 0;
                }
                const loadingService = await showLoading('Saving...');

                try {
                    let data = {
                        status: status.value, //done
                        property_or_room: property.value, //done
                        alt_rental_property: store.WorkOrderFormProperties.find(
                            (storeProperty) => storeProperty.id == property.value
                        ).name, //done
                        reported_by_type: reporterType.value, //done ****************************************************
                        reporter_team_member: reporterType.value == 'TEAM_MEMBER' ? reporter.value : '', //done
                        reporter_owner: reporterType.value == 'OWNER' ? reporter.value : '', //done
                        alt_reporter: reporterType.value == 'OTHER' ? reporter.value : '', //done
                        account_assignee: accountAssignee.value, // done *
                        account: account.value, // done *
                        job_type: jobType.value, // done
                        priority: priority.value, // done
                        date_reported: workOrderDetails.value.date_reported, // done
                        description: workOrderDescription.value, // done
                        start_date: workOrderDetails.value.start_date, // done
                        completed_date: workOrderDetails.value.completed_date, // done
                        amount: workOrderDetails.value.amount, // done

                        invoice_number: vendorInvoiceNumber.value || '', // done
                        receipt_number: receiptNumber.value || '', // done
                        invoice_vendor: vendorInvoiceVendorId.value || '', // done
                        receipt_store: receiptStoreId.value || '', // done

                        assigned_to: assigneeType.value || '', // done
                        team_member: assigneeType.value != 'VENDOR' ? assignee.value || '' : '', // done
                        team_member_type: assigneeType.value != 'VENDOR' ? assignee.value : '', // done
                        vendor: assigneeType.value == 'VENDOR' ? assignee.value || '' : '', // done

                        also_notify: alsoNotifyType.value || '', // done
                        also_notify_team_member: alsoNotifyType.value != 'VENDOR' ? alsoNotify.value || '' : '', // done
                        also_notify_vendor: alsoNotifyType.value == 'VENDOR' ? alsoNotify.value || '' : '', // done

                        email_details: workOrderDetails.value.email_details, // done
                        notes: workOrderDetails.value.notes, // done
                        vendor_team_member_notes: workOrderDetails.value.vendor_team_member_notes, // done
                    } as any;

                    let formData = new FormData();
                    for (let key in data) {
                        if (data[key] != null) {
                            formData.append(key, data[key]);
                        }
                    }

                    if (materials.value && materials.value.length > 0) {
                        materials.value.forEach((material) => {
                            formData.append('material_expense_dates', material.date);
                            formData.append('material_expense_descriptions', material.description);
                            formData.append('material_expense_qties', material.qty);
                            formData.append('material_expense_prices', material.price);
                            formData.append('material_expense_ids', material.id);
                        });
                    }

                    if (photos.value && photos.value.length > 0) {
                        photos.value.forEach((photo) => {
                            formData.append('photos_to_create', photo.file);
                            formData.append('photo_captions_to_create', photo.caption);
                        });
                    }

                    const response = await AppAPI.createWorkOrder(formData);
                    hideLoading(loadingService);
                } catch (error) {
                    hideLoading(loadingService);
                    //console.log('error', error);
                }

                return modalController.dismiss({}, 'confirm');
            };

            const formatDate = (date: string) => {
                let [year, month, day] = date.split('-');
                return format(new Date(Number(year), Number(month) - 1, Number(day)), 'MMMM dd, yyyy');
            };

            const getTodayDate = () => {
                const today = new Date();
                const year = today.getFullYear();
                const month = today.getMonth();
                const day = today.getDate();
                return format(new Date(year, month, day), 'yyyy-MM-dd');
            };

            const loadImages = async () => {
                const images = [] as any[];
                const loadingService = await showLoading('Loading Photos');
                try {
                    for (const photo of workOrderDetails.value.photos) {
                        let imageObject = {} as any;
                        const image = await AppAPI.getWorkOrderPhoto(photo.id, accessToken.value);
                        imageObject['image'] = image;
                        imageObject['caption'] = photo.caption;
                        imageObject['id'] = photo.id;
                        imageObject['photo_name'] = photo.photo_name;
                        imageObject['deleted'] = false;
                        images.push(imageObject);
                    }
                    hideLoading(loadingService);
                } catch (error) {
                    hideLoading(loadingService);
                }
                return images;
            };

            onMounted(async () => {
                if (props.workOrderId) {
                    const loadingService = await showLoading('Loading...');
                    try {
                        const data = await AppAPI.getWorkOrderDetails(props.workOrderId);
                        workOrderDetails.value = data.data;
                        if (!workOrderDetails.value.status && !workOrderDetails.value.active) {
                            hideLoading(loadingService);
                            errorToast('Work Order is not valid.');
                            cancel();
                            return;
                        }
                        if (workOrderDetails.value.description) {
                            workOrderDescription.value = workOrderDetails.value.description;
                        }
                        if (!workOrderDetails.value.rental_property) {
                            hideLoading(loadingService);
                            return;
                        }
                        const owners = await AppAPI.getWorkOrderPropertyOwners(workOrderDetails.value.rental_property);
                        workOrderOwners.value = owners.data;
                        hideLoading(loadingService);
                    } catch (error) {
                        console.log('error', error);
                        hideLoading(loadingService);
                    }
                    assignee.value = workOrderDetails.value.assigned_to_id;
                    assigneeType.value = workOrderDetails.value.assigned_to_type;
                    status.value = workOrderDetails.value.status;
                    property.value = workOrderDetails.value.rental_property;
                    reporterType.value = workOrderDetails.value.reporter_type;
                    reporter.value = workOrderDetails.value.reporter || workOrderDetails.value.alt_reporter;
                    accountAssignee.value = workOrderDetails.value.account_assignee;
                    account.value = workOrderDetails.value.account;
                    jobType.value = workOrderDetails.value.job_type;
                    priority.value = workOrderDetails.value.priority;

                    vendorInvoiceNumber.value = workOrderDetails.value.invoice_number;
                    vendorInvoiceVendorId.value = workOrderDetails.value.invoice_vendor || '';
                    receiptNumber.value = workOrderDetails.value.receipt_number;
                    receiptStoreId.value = workOrderDetails.value.receipt_store || '';

                    alsoNotify.value = workOrderDetails.value.notify_to_id;
                    alsoNotifyType.value = workOrderDetails.value.notify_to_type;

                    damageNoticeSource.value = workOrderDetails.value.damage_notice_source;

                    if (workOrderDetails.value.photos.length > 0) {
                        workOrderImages.value = await loadImages();
                    }
                } else {
                    const reported = {
                        type: 'TEAM_MEMBER',
                        id: store.Id,
                    };

                    const workOrderInitData = {
                        status: store.workOrderFormOptions.status_choices[0].id,
                        property_or_room: store.workOrderFormProperties[0].id,
                        alt_rental_property: '',
                        property: store.workOrderFormProperties[0].id,
                        reported_by_type: reported.type,
                        reporter_team_member: reported.type == 'TEAM_MEMBER' ? reported.id : '',
                        reporter_owner: '',
                        alt_reporter: '',
                        account: store.WorkOrderExpenseAccoutns[0].id,
                        account_assignee: 'SUBSCRIBER',
                        job_type: store.workOrderJobTypes[0].id,
                        priority: store.workOrderFormOptions.priority_choices[2].id,
                        date_reported: getTodayDate(),
                        description: '',
                        start_date: '',
                        completed_date: '',
                        amount: '0',
                        invoice_number: '',
                        receipt_number: '',
                        invoice_vendor: '',
                        receipt_store: '',
                        assigned_to: '',
                        team_member: '',
                        team_member_type: '',
                        vendor: '',
                        also_notify: '',
                        also_notify_team_member: '',
                        also_notify_vendor: '',
                        email_details: true,
                        notes: '',
                        vendor_team_member_notes: '',
                        photos: [],
                        rental_property: store.workOrderFormProperties[0].id,
                    };

                    workOrderDetails.value = workOrderInitData;
                    property.value = store.workOrderFormProperties[0].id;
                    status.value = store.workOrderFormOptions.status_choices[0].id;
                    reporterType.value = workOrderDetails.value.reporter_type;
                    reporter.value =
                        workOrderDetails.value.reporter || workOrderDetails.value.reporter_team_member || '';
                    accountAssignee.value = workOrderDetails.value.account_assignee;
                    account.value = workOrderDetails.value.account;
                    jobType.value = workOrderDetails.value.job_type;
                    priority.value = workOrderDetails.value.priority;
                    reporterType.value = workOrderDetails.value.reported_by_type;
                }
            });

            const loadMaterialsExpenses = async () => {
                //const loadingService = await showLoading('Loading...');
                if (!props.workOrderId) {
                    return;
                }
                try {
                    const repsonse = await AppAPI.getWorkOrderMaterialExpenses(
                        props.workOrderId,
                        0,
                        100,
                        'id',
                        false,
                        ''
                    );
                    materialsExpenses.value = repsonse.data;
                    //hideLoading(loadingService);
                } catch (error) {
                    console.log('error', error);
                    //hideLoading(loadingService);
                }
            };

            const deletePhoto = async (id: number | string, isWorkOrderImage: boolean) => {
                const response = await advancedAlert('Alert', '', 'Are you sure you want to delete the image?', [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                    },
                    {
                        text: 'Confirm',
                        role: 'confirm',
                    },
                ]);
                if (response.role != 'confirm') {
                    return;
                }

                if (!isWorkOrderImage) {
                    photos.value = photos.value.filter((photo, index) => index != id);
                    return;
                }

                workOrderImages.value = workOrderImages.value.map((image: any) => {
                    if (image.id == id) {
                        image.deleted = true;
                    }
                    return image;
                });
                workOrderImagesToDelete.value.push({ id: id });
            };

            const downloadPhoto = async (id: string) => {
                const data = workOrderDetails.value.photos.find((image: any) => image.id == id);
                const image = await AppAPI.getWorkOrderPhoto(id, accessToken.value, false);
                if (!image) {
                    errorToast('Cannot download photo');
                    return;
                }
                const imageObject = {
                    image: image,
                    id: id,
                    photo_name: data.photo_name,
                };
                console.log(imageObject.image);

                if (Capacitor.getPlatform() != 'web') {
                    infoToast('Image downloads are currently unavailable');
                    /*await Share.share({
                        files: [imageObject.image],
                    });*/
                } else {
                    const link = document.createElement('a');
                    link.href = imageObject.image;
                    link.download = imageObject.photo_name;
                    link.click();
                }
            };

            const openCreateMaterialExpenseModal = async () => {
                if (!props.workOrderId) {
                    const data = await ModalController.workOrderDetailsMaterialExpenseModal(props.workOrderId, null);
                    materials.value.push(data.data);
                    return;
                }
                await ModalController.workOrderDetailsMaterialExpenseModal(props.workOrderId, null);
                await loadMaterialsExpenses();
            };

            const editCreateMaterialExpenseModal = async (data: any) => {
                if (!props.workOrderId) {
                    const result = await ModalController.workOrderDetailsMaterialExpenseModal(props.workOrderId, data);
                    materials.value = materials.value.map((material) => {
                        if (material.id == data.id) {
                            return result.data;
                        }
                        return material;
                    });
                    return;
                }
                await ModalController.workOrderDetailsMaterialExpenseModal(props.workOrderId, data);
                await loadMaterialsExpenses();
            };

            const deleteMaterialExpense = async (id: number | string) => {
                if (!props.workOrderId) {
                    materials.value = materials.value.filter((material) => material.id != id);
                    return;
                }

                const response = await advancedAlert('Alert', '', 'Are you sure you want to delete this expense?', [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                    },
                    {
                        text: 'Confirm',
                        role: 'confirm',
                    },
                ]);
                if (response.role != 'confirm') {
                    return;
                }

                const loadingService = await showLoading('Loading...');
                try {
                    const repsonse = await AppAPI.deleteWorkOrderMaterialExpense(id);
                    hideLoading(loadingService);
                } catch (error) {
                    console.log('error', error);
                    hideLoading(loadingService);
                }
                await loadMaterialsExpenses();
            };

            const uploadPhoto = async () => {
                const im = await Camera.getPhoto({
                    quality: 90,
                    allowEditing: false,
                    resultType: CameraResultType.Base64,
                }).catch((error) => {
                    return;
                });

                if (!im || !im.base64String) {
                    return;
                }

                const fileName = `photo_${new Date().getTime()}.${im.format}`;

                const ls = await showLoading('Uploading Photo');
                const formData = new FormData();

                const byteCharacters = atob(im.base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'image/jpeg' });
                formData.append('photo', blob, fileName);

                try {
                    const response = await AppAPI.uploadWorkOrderPhoto(formData);
                    if (!response) {
                        throw new Error('Error uploading photo');
                    }
                    const file = new File([response.data], fileName, { type: response.headers['content-type'] });
                    const [dataURL, imageResolution] = await Promise.all([getDataURL(file), getImageResolution(file)]);
                    photos.value.push({
                        file: file,
                        thumbnail_url: dataURL,
                        photo_url: dataURL,
                        photo_name: file.name,
                        resolution: `${(imageResolution as { width: number; height: number }).width}x${
                            (imageResolution as { width: number; height: number }).height
                        }`,
                        caption: '',
                        deleted: false,
                    });
                    updateSwiper();
                    hideLoading(ls);
                } catch (error) {
                    console.log('Upload Error:', error);
                    errorToast('Error uploading photo');
                    hideLoading(ls);
                }
            };

            const updateCaption = async (id: number, isWorkOrderImage: boolean) => {
                if (workOrderImages.value?.find((image: any) => image.id == id)?.deleted) {
                    const response = await advancedAlert('Alert', '', 'Do you want to recover the image?', [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                        },
                        {
                            text: 'Confirm',
                            role: 'confirm',
                        },
                    ]);
                    if (response.role != 'confirm') {
                        return;
                    }
                    workOrderImages.value = workOrderImages.value.map((image: any) => {
                        if (image.id == id) {
                            image.deleted = false;
                        }
                        return image;
                    });
                    workOrderImagesToDelete.value = workOrderImagesToDelete.value.filter(
                        (image: any) => image.id != id
                    );
                    return;
                }
                let captionPrior = '';
                if (isWorkOrderImage) {
                    captionPrior = workOrderImages.value.find((image: any) => image.id == id).caption;
                } else {
                    captionPrior = photos.value[id].caption;
                }

                const response = await basicInputAlert('Edit Caption', '', '', 'Enter caption', captionPrior);
                if (response.role != 'confirm') {
                    return;
                }

                const caption = response.data.values.input;
                if (isWorkOrderImage) {
                    workOrderImages.value = workOrderImages.value.map((image: any) => {
                        if (image.id == id) {
                            image.caption = caption || '';
                        }
                        return image;
                    });
                } else {
                    photos.value[id].caption = caption || '';
                }
            };

            watch(
                () => property.value,
                async (newValue: any, oldValue: any) => {
                    const owners = await AppAPI.getWorkOrderPropertyOwners(property.value);
                    workOrderOwners.value = owners.data;
                }
            );

            watch(
                () => segmentValue.value,
                async (newValue: any) => {
                    if (newValue == 'materials-and-expenses') {
                        await loadMaterialsExpenses();
                    }
                }
            );

            watch(
                () => reporterType.value,
                async (newValue: any, oldValue: any) => {
                    if (!oldValue) {
                        return;
                    }
                    if (newValue == 'OTHER') {
                        reporter.value = '';
                    } else if (newValue == 'TEAM_MEMBER') {
                        reporter.value = store.WorkOrderTeamMembers[0].id;
                    } else if (newValue == 'OWNER') {
                        reporter.value = workOrderOwners.value[0].id;
                    }
                }
            );

            watch(
                () => assigneeType.value,
                async (newValue: any, oldValue: any) => {
                    if (newValue == '') {
                        assignee.value = '';
                        alsoNotify.value = '';
                        alsoNotifyType.value = '';
                    }
                }
            );

            watch(
                () => alsoNotifyType.value,
                async (newValue: any, oldValue: any) => {
                    if (newValue == '') {
                        alsoNotify.value = '';
                    }
                }
            );

            const isNumber = (evt: any) => {
                evt = evt ? evt : window.event;
                var charCode = evt.which ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            };

            const updateSwiper = () => {
                isShowPhotos.value = false;
                setTimeout(() => {
                    isShowPhotos.value = true;
                }, 100);
            };

            const isCurrency = (evt: any) => {
                return (evt: any) => {
                    evt = evt ? evt : window.event;
                    var charCode = evt.which ? evt.which : evt.keyCode;
                    if (
                        (charCode > 31 && (charCode < 48 || charCode > 57)) ||
                        (charCode == 46 && evt.target.value.indexOf('.') != -1)
                    ) {
                        evt.preventDefault();
                    } else {
                        return true;
                    }
                };
            };

            const vendorInvoiceNumberCheck = (event: any) => {
                vendorInvoiceNumber.value = event.target.value.replace(/\D/g, '');
            };

            const receiptNumberCheck = (event: any) => {
                receiptNumber.value = event.target.value.replace(/\D/g, '');
            };

            const openInvoice = async () => {
                AppFunctions.transitionClear(`/reservationsList?open=${damageNoticeSource.value.meta_reservation_id}`);
                return modalController.dismiss(null, 'cancel');
            };

            return {
                arrowBackOutline,
                store,
                workOrderDetails,
                workOrderImages,
                workOrderImagesToDelete,
                workOrderDescription,
                property,
                status,
                reporterType,
                assigneeType,
                assignee,
                reporter,
                workOrderOwners,
                segmentValue,
                materialsExpenses,
                accountAssignee,
                account,
                jobType,
                priority,
                add,
                refresh,
                create,
                trashBin,
                alsoNotify,
                alsoNotifyType,
                vendorInvoiceNumber,
                vendorInvoiceVendorId,
                receiptNumber,
                receiptStoreId,
                materials,
                photos,
                formatOptions,
                isShowPhotos,
                downloadOutline,
                trash,
                Capacitor,
                damageNoticeSource,
                openInvoice,
                updateCaption,
                deletePhoto,
                getTodayDate,
                openCreateMaterialExpenseModal,
                editCreateMaterialExpenseModal,
                cancel,
                confirm,
                formatDate,
                loadMaterialsExpenses,
                deleteMaterialExpense,
                createWorkOrder,
                uploadPhoto,
                isCurrency,
                isNumber,
                downloadPhoto,

                vendorInvoiceNumberCheck,
                receiptNumberCheck,
                reservationAccess,
            };
        },
    });
</script>

<style scoped>
    ion-item {
        --background: transparent;
    }

    ion-list {
        background: transparent;
    }

    .image-wrapper {
        display: flex;
        overflow-x: auto;
    }

    .image-item {
        flex: 0 0 auto;
        /* Ensure items don't grow beyond their intrinsic size */
        min-width: 0;
        /* Allow items to shrink beyond their content size */
        overflow: hidden;
        /* Allow content to be clipped if it overflows */
        margin: 5px;
    }

    .card {
        background: white;
        margin: 10px;
        border-radius: 10px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    }
</style>
