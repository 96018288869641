import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icons/vip.svg'
import _imports_1 from '@/assets/icons/blocklist.svg'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  slot: "end",
  style: {"padding-right":"0px","margin-left":"8px"},
  src: _imports_0
}
const _hoisted_3 = {
  key: 1,
  slot: "end",
  style: {"padding-right":"0px","margin-left":"8px"},
  src: _imports_1
}
const _hoisted_4 = { class: "address" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { style: {"margin":"10px","margin-bottom":"0px","padding-top":"20px","display":"flex","flex-direction":"row","justify-content":"space-between","align-items":"center"} }
const _hoisted_9 = { style: {"font-size":"18px","font-weight":"700"} }
const _hoisted_10 = {
  key: 2,
  style: {"margin":"10px","color":"#777","font-weight":"400"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper_container = _resolveComponent("swiper-container")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, {
    title: `Guest Information`,
    background: "rgb(239, 246, 255)"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "primary",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cancel()))
                  }, {
                    default: _withCtx(() => _cache[21] || (_cache[21] = [
                      _createTextVNode("Back")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_ctx.guest)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ion_item, {
              class: "ion-no-padding item",
              lines: "full"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { style: {"padding-left":"16px"} }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Name")
                  ])),
                  _: 1
                }),
                _createVNode(_component_ion_label, {
                  "text-wrap": "",
                  slot: "end",
                  style: {"text-align":"end"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.guest.title?.name || '') + " " + _toDisplayString(_ctx.guest.first_name) + " " + _toDisplayString(_ctx.guest.last_name), 1)
                  ]),
                  _: 1
                }),
                (_ctx.guest.status_id == 2)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_2))
                  : _createCommentVNode("", true),
                (_ctx.guest.status_id == 1)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_3))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            (_ctx.guest.email)
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 0,
                  class: "ion-no-padding item",
                  lines: "full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { style: {"padding-left":"16px"} }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode("Email")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_label, {
                      "text-wrap": "",
                      slot: "end",
                      style: {"text-align":"end"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.guest.email), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_icon, {
                      slot: "end",
                      style: {"margin-left":"10px","cursor":"pointer"},
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.AppFunctions.openEmail(_ctx.guest.email))),
                      icon: _ctx.mailOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.guestId)
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 1,
                  class: "ion-no-padding item",
                  lines: "full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { style: {"padding-left":"16px"} }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("ID")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_label, {
                      "text-wrap": "",
                      slot: "end",
                      style: {"text-align":"end"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.guestId), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_item, {
              class: "ion-no-padding item",
              lines: "full"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { style: {"padding-left":"16px"} }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Address")
                  ])),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.guest.address.address1)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.guest.address.address1), 1))
                    : _createCommentVNode("", true),
                  (_ctx.guest.address.address2)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.guest.address.address2), 1))
                    : _createCommentVNode("", true),
                  (_ctx.guest.address.city || _ctx.guest.address.state?.code || _ctx.guest.address.zip)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.guest.address.city) + ", " + _toDisplayString(_ctx.guest.address.state?.code || '') + " " + _toDisplayString(_ctx.guest.address.zip), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("p", null, _toDisplayString(_ctx.guest.address.country?.name || ''), 1)
                ])
              ]),
              _: 1
            }),
            (_ctx.guest.date_of_birth)
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 2,
                  class: "ion-no-padding item",
                  lines: "full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { style: {"padding-left":"16px"} }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("Date of Birth")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.dob), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.guest.mobile_phone)
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 3,
                  class: "ion-no-padding item",
                  lines: "full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { style: {"padding-left":"16px"} }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("Phone (mobile)")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.guest.mobile_phone), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.guest.phone)
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 4,
                  class: "ion-no-padding item",
                  lines: "full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { style: {"padding-left":"16px"} }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("Phone (home)")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.guest.phone), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.guest.work_phone)
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 5,
                  class: "ion-no-padding item",
                  lines: "full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { style: {"padding-left":"16px"} }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode("Phone (work)")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.guest.work_phone), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.guest.work_phone_ext)
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 6,
                  class: "ion-no-padding item",
                  lines: "full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { style: {"padding-left":"16px"} }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode("Ext")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.guest.work_phone_ext), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.guest.fax)
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 7,
                  class: "ion-no-padding item",
                  lines: "full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { style: {"padding-left":"16px"} }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode("Fax")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.guest.fax), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.guest.note)
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 8,
                  class: "ion-no-padding item",
                  lines: "full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { style: {"padding-left":"16px"} }, {
                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createTextVNode("Notes")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ion_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.guest.note), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _cache[14] || (_cache[14] = _createElementVNode("p", { class: "note" }, "Guest information can only be edited on Lodgix.com.", -1))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        _cache[16] || (_cache[16] = _createElementVNode("p", { style: {"font-size":"x-large","color":"#20469b","font-weight":"700","margin":"0px","padding":"0px"} }, " Recent Invoices ", -1)),
        (_ctx.invoices && _ctx.invoices.length > 0)
          ? (_openBlock(), _createBlock(_component_ion_button, {
              key: 0,
              size: "small",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.viewAllInvoices()))
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode("View All")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      (_ctx.invoices && _ctx.invoices.length > 0)
        ? (_openBlock(), _createBlock(_component_swiper_container, {
            key: 1,
            "slides-per-view": 1,
            "centered-slides": true,
            navigation: true,
            style: {"border-radius":"10px !important"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoices, (invoice) => {
                return (_openBlock(), _createBlock(_component_swiper_slide, {
                  key: invoice,
                  style: {"padding":"10px 25px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card, { class: "ion-no-margin" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card_content, { style: {"padding":"10px"} }, {
                          default: _withCtx(() => [
                            _createElementVNode("h3", _hoisted_9, "Invoice #" + _toDisplayString(invoice.id), 1),
                            _createVNode(_component_ion_list, { lines: "full" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                                        _createTextVNode("Property")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(invoice.properties[0].name || 'N/A'), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                                        _createTextVNode("From Date")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatDate(invoice.from_date)), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                                        _createTextVNode("To Date")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatDate(invoice.to_date)), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => _cache[20] || (_cache[20] = [
                                        _createTextVNode("Total Amount")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.formatPrice(invoice.total)), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("p", _hoisted_10, "No invoices found"))
    ]),
    _: 1
  }))
}