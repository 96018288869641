<template>
    <modal-base-layout :title="`Guest Information`" background="rgb(239, 246, 255)">
        <div v-if="guest">
            <ion-item class="ion-no-padding item" lines="full">
                <ion-label style="padding-left: 16px">Name</ion-label>
                <ion-label text-wrap slot="end" style="text-align: end"
                    >{{ guest.title?.name || '' }} {{ guest.first_name }} {{ guest.last_name }}
                </ion-label>
                <img
                    slot="end"
                    style="padding-right: 0px; margin-left: 8px"
                    v-if="guest.status_id == 2"
                    src="@/assets/icons/vip.svg"
                />
                <img
                    slot="end"
                    style="padding-right: 0px; margin-left: 8px"
                    v-if="guest.status_id == 1"
                    src="@/assets/icons/blocklist.svg"
                />
            </ion-item>
            <ion-item v-if="guest.email" class="ion-no-padding item" lines="full">
                <ion-label style="padding-left: 16px">Email</ion-label>
                <ion-label text-wrap slot="end" style="text-align: end">{{ guest.email }}</ion-label>
                <ion-icon
                    slot="end"
                    style="margin-left: 10px; cursor: pointer"
                    @click="AppFunctions.openEmail(guest.email)"
                    :icon="mailOutline"
                >
                </ion-icon>
            </ion-item>
            <ion-item class="ion-no-padding item" lines="full" v-if="guestId">
                <ion-label style="padding-left: 16px">ID</ion-label>
                <ion-label text-wrap slot="end" style="text-align: end">{{ guestId }}</ion-label>
            </ion-item>

            <ion-item class="ion-no-padding item" lines="full">
                <ion-label style="padding-left: 16px">Address</ion-label>
                <div class="address">
                    <p v-if="guest.address.address1">{{ guest.address.address1 }}</p>
                    <p v-if="guest.address.address2">{{ guest.address.address2 }}</p>
                    <p v-if="guest.address.city || guest.address.state?.code || guest.address.zip">
                        {{ guest.address.city }}, {{ guest.address.state?.code || '' }} {{ guest.address.zip }}
                    </p>
                    <p>{{ guest.address.country?.name || '' }}</p>
                </div>
            </ion-item>
            <ion-item class="ion-no-padding item" lines="full" v-if="guest.date_of_birth">
                <ion-label style="padding-left: 16px">Date of Birth</ion-label>
                <ion-text>{{ dob }}</ion-text>
            </ion-item>
            <ion-item class="ion-no-padding item" lines="full" v-if="guest.mobile_phone">
                <ion-label style="padding-left: 16px">Phone (mobile)</ion-label>
                <ion-text>{{ guest.mobile_phone }}</ion-text>
            </ion-item>
            <ion-item class="ion-no-padding item" lines="full" v-if="guest.phone">
                <ion-label style="padding-left: 16px">Phone (home)</ion-label>
                <ion-text>{{ guest.phone }}</ion-text>
            </ion-item>
            <ion-item class="ion-no-padding item" lines="full" v-if="guest.work_phone">
                <ion-label style="padding-left: 16px">Phone (work)</ion-label>
                <ion-text>{{ guest.work_phone }}</ion-text>
            </ion-item>
            <ion-item class="ion-no-padding item" lines="full" v-if="guest.work_phone_ext">
                <ion-label style="padding-left: 16px">Ext</ion-label>
                <ion-text>{{ guest.work_phone_ext }}</ion-text>
            </ion-item>
            <ion-item class="ion-no-padding item" lines="full" v-if="guest.fax">
                <ion-label style="padding-left: 16px">Fax</ion-label>
                <ion-text>{{ guest.fax }}</ion-text>
            </ion-item>
            <ion-item class="ion-no-padding item" lines="full" v-if="guest.note">
                <ion-label style="padding-left: 16px">Notes</ion-label>
                <ion-text>{{ guest.note }}</ion-text>
            </ion-item>

            <p class="note">Guest information can only be edited on Lodgix.com.</p>
        </div>

        <div
            style="
                margin: 10px;
                margin-bottom: 0px;
                padding-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            "
        >
            <p style="font-size: x-large; color: #20469b; font-weight: 700; margin: 0px; padding: 0px">
                Recent Invoices
            </p>
            <ion-button v-if="invoices && invoices.length > 0" size="small" @click="viewAllInvoices()"
                >View All</ion-button
            >
        </div>

        <template v-if="invoices && invoices.length > 0">
            <swiper-container
                :slides-per-view="1"
                :centered-slides="true"
                :navigation="true"
                style="border-radius: 10px !important"
            >
                <template v-for="invoice in invoices" :key="invoice">
                    <swiper-slide style="padding: 10px 25px">
                        <ion-card class="ion-no-margin">
                            <ion-card-content style="padding: 10px">
                                <h3 style="font-size: 18px; font-weight: 700">Invoice #{{ invoice.id }}</h3>
                                <ion-list lines="full">
                                    <ion-item>
                                        <ion-label>Property</ion-label>
                                        <ion-text>{{ invoice.properties[0].name || 'N/A' }}</ion-text>
                                    </ion-item>
                                    <ion-item>
                                        <ion-label>From Date</ion-label>
                                        <ion-text>{{ formatDate(invoice.from_date) }}</ion-text>
                                    </ion-item>
                                    <ion-item>
                                        <ion-label>To Date</ion-label>
                                        <ion-text>{{ formatDate(invoice.to_date) }}</ion-text>
                                    </ion-item>
                                    <ion-item>
                                        <ion-label>Total Amount</ion-label>
                                        <ion-text>{{ formatPrice(invoice.total) }}</ion-text>
                                    </ion-item>
                                </ion-list>
                            </ion-card-content>
                        </ion-card>
                    </swiper-slide>
                </template>
            </swiper-container>
        </template>

        <template v-else>
            <p style="margin: 10px; color: #777; font-weight: 400">No invoices found</p>
        </template>

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="primary" @click="cancel()">Back</ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController } from '@ionic/vue';
    import { computed, defineComponent, onMounted, ref } from 'vue';
    import { format } from 'date-fns';
    import { useStore } from '@/store/store';
    import AppAPI from '@/services/AppAPI';
    import { errorToast } from '@/services/Toast';
    import AppFunctions from '@/services/AppFunctions';
    import { formatDate } from '@/utils/Date';
    import { mailOutline } from 'ionicons/icons';
    import { hideLoading, showLoading } from '@/services/Loading';

    export default defineComponent({
        components: {},
        props: {
            guestId: {
                type: Number,
                required: false,
                default: null,
            },
        },
        setup(props) {
            const store = useStore();
            const guest = ref();
            const statusList = ref();
            const invoices = ref();

            const dob = computed(() => {
                if (guest.value && guest.value.date_of_birth) {
                    return format(new Date(guest.value.date_of_birth), 'MM/dd/yyyy');
                }
                return 'N/A';
            });

            const statusLabel = computed(() => {
                if (statusList.value && guest.value) {
                    const status = statusList.value.find((status: any) => status.id === guest.value.status_id);
                    return status ? status.name : '';
                }
                return '';
            });

            const cancel = async () => {
                return modalController.dismiss(null, 'cancel');
            };

            const confirm = async () => {
                return modalController.dismiss(null, 'cancel');
            };

            const viewAllInvoices = async () => {
                AppFunctions.transitionClear(`/reservationsList?search=${props.guestId}`);
                return modalController.dismiss(null, 'cancel');
            };

            const formatPrice = (value: any) => {
                var format = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: store.userCurrency,
                });
                return format.format(value);
            };

            onMounted(async () => {
                const loading = await showLoading('Loading...');
                try {
                    if (props.guestId) {
                        const response = await AppAPI.getGuestById(props.guestId);
                        if (!response) {
                            errorToast('Failed to fetch guest');
                            cancel();
                        } else {
                            guest.value = response.data;
                        }

                        statusList.value = (await AppAPI.getGuestStatusList()).data;
                        invoices.value = (await AppAPI.getGuestInvoices(props.guestId.toString())).data;
                        invoices.value = invoices.value.sort((a: any, b: any) => {
                            return new Date(b.from_date).getTime() - new Date(a.from_date).getTime();
                        });
                    }
                } catch (e) {
                    errorToast('Failed to fetch guest');
                    cancel();
                } finally {
                    hideLoading(loading);
                }
            });

            return {
                store,
                guest,
                statusLabel,
                dob,
                invoices,
                AppFunctions,
                mailOutline,
                formatDate,
                cancel,
                confirm,
                formatPrice,
                viewAllInvoices,
            };
        },
    });
</script>

<style scoped>
    .header-container {
        display: flex;
        flex-direction: column;
    }

    .address {
        text-align: right;
        font-size: small;
    }

    .note {
        padding: 5px 16px;
        font-style: italic;
        color: #777;
        margin: 0px;
        font-size: small;
    }

    .item {
        --background: transparent;
        margin-top: 0px;
        margin-bottom: 0px;
    }
</style>
