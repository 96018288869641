import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, { lines: "full" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Total")
            ])),
            _: 1
          }),
          _createVNode(_component_ion_label, {
            slot: "end",
            style: {"text-align":"end"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatPrice(_ctx.data.total)), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Total with Deposits")
            ])),
            _: 1
          }),
          _createVNode(_component_ion_label, {
            slot: "end",
            style: {"text-align":"end"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatPrice(_ctx.data.totalDeposits)), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.data.confirmationAmount >= 0)
        ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Confirmation Amount")
                ])),
                _: 1
              }),
              _createVNode(_component_ion_label, {
                slot: "end",
                style: {"text-align":"end"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatPrice(_ctx.data.confirmationAmount)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Paid")
            ])),
            _: 1
          }),
          _createVNode(_component_ion_label, {
            slot: "end",
            style: {"text-align":"end"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatPrice(_ctx.data.paid)), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}