<template>
    <ion-accordion-group v-if="isMultipleProperties" :multiple="true">
        <ion-accordion v-for="(trigger, index) in triggers" :key="index" :value="index">
            <ion-item slot="header">
                <ion-label>{{ trigger.trigger_title }}</ion-label>
            </ion-item>
            <div slot="content" style="padding-left: 15px">
                <ion-item v-for="item in trigger.sub_triggers" :key="item.id_trigger" lines="full">
                    <ion-label>
                        <h2>{{ item.property_name }}</h2>
                        <template v-for="timestamp in item.timestamps" :key="timestamp">
                            <p>{{ timestamp }}</p>
                        </template>
                        <p v-if="item.timestamps.length <= 0">Not Sent</p>
                    </ion-label>
                    <div>
                        <ion-button @click="sendTrigger(item)" size="small"
                            ><ion-icon slot="start" :src="paperPlaneOutline" />{{
                                item.timestamps.length > 0 ? 'Resend' : 'Send'
                            }}</ion-button
                        >
                    </div>
                    <div style="padding-left: 10px">
                        <ion-toggle
                            :disabled="!item.can_change_is_active"
                            v-model="item.is_active"
                            @ionChange="toggleTrigger(item)"
                        />
                    </div>
                </ion-item>
            </div>
        </ion-accordion>
    </ion-accordion-group>

    <template v-else>
        <template v-for="(trigger, index) in triggers" :key="index">
            <ion-item v-if="trigger.id_tech_reservation" lines="full">
                <ion-label>
                    <h2>{{ trigger.trigger_title }}</h2>
                    <template v-for="timestamp in trigger.timestamps" :key="timestamp">
                        <p>{{ timestamp }}</p>
                    </template>
                    <p v-if="trigger.timestamps.length <= 0">Not Sent</p>
                </ion-label>
                <div>
                    <ion-button @click="sendTrigger(trigger)" size="small"
                        ><ion-icon slot="start" :src="paperPlaneOutline" />{{
                            trigger.timestamps.length > 0 ? 'Resend' : 'Send'
                        }}</ion-button
                    >
                </div>
                <div style="padding-left: 10px">
                    <ion-toggle
                        :disabled="!trigger.can_change_is_active"
                        v-model="trigger.is_active"
                        @ionChange="toggleTrigger(trigger)"
                    />
                </div>
            </ion-item>
        </template>
    </template>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { paperPlaneOutline } from 'ionicons/icons';
    import { hideLoading, showLoading } from '@/services/Loading';
    import AppAPI from '@/services/AppAPI';
    import { errorToast, successToast } from '@/services/Toast';
    import { advancedAlert } from '@/services/AlertController';

    export default defineComponent({
        props: {
            triggers: {
                type: Object,
                required: true,
            },
            isMultipleProperties: {
                type: Boolean,
                required: true,
            },
            invoiceNumber: {
                type: String,
                required: true,
            },
        },
        emits: ['getTriggers'],
        setup(props, ctx) {
            const toggleTrigger = async (trigger: any) => {
                const loading = await showLoading('Updating trigger...');
                try {
                    await AppAPI.toggleInvoiceTrigger(
                        trigger.id_tech_reservation,
                        props.invoiceNumber,
                        trigger.id_trigger
                    );
                    ctx.emit('getTriggers', props.invoiceNumber);
                } catch (e) {
                    errorToast('Error updating trigger');
                    ctx.emit('getTriggers', props.invoiceNumber);
                } finally {
                    setTimeout(() => {
                        hideLoading(loading);
                    }, 1000);
                }
            };

            const sendTrigger = async (trigger: any) => {
                const response = await advancedAlert(
                    'Send Trigger',
                    '',
                    'Are you sure you want to manually send this trigger now? Trigger will still be sent automatically if criteria applies.',
                    [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                        },
                        {
                            text: 'Ok',
                            role: 'confirm',
                        },
                    ]
                );
                if (response.role != 'confirm') {
                    return;
                }
                await AppAPI.sendInvoiceTrigger(trigger.id_tech_reservation, trigger.id_trigger);
                ctx.emit('getTriggers', props.invoiceNumber);
                successToast('Trigger queued');
            };

            return {
                paperPlaneOutline,
                toggleTrigger,
                sendTrigger,
            };
        },
    });
</script>
