<template>
    <ion-list lines="full">
        <ion-item>
            <ion-label>Total</ion-label>
            <ion-label slot="end" style="text-align: end">{{ formatPrice(data.total) }}</ion-label>
        </ion-item>
        <ion-item>
            <ion-label>Total with Deposits</ion-label>
            <ion-label slot="end" style="text-align: end">{{ formatPrice(data.totalDeposits) }}</ion-label>
        </ion-item>
        <ion-item v-if="data.confirmationAmount >= 0">
            <ion-label>Confirmation Amount</ion-label>
            <ion-label slot="end" style="text-align: end">{{ formatPrice(data.confirmationAmount) }}</ion-label>
        </ion-item>
        <ion-item>
            <ion-label>Paid</ion-label>
            <ion-label slot="end" style="text-align: end">{{ formatPrice(data.paid) }}</ion-label>
        </ion-item>
    </ion-list>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { useStore } from '@/store/store';

    export default defineComponent({
        props: {
            data: {
                type: Object,
                required: true,
            },
        },
        emits: [''],
        setup(props, ctx) {
            const store = useStore();
            const formatPrice = (value: number) => {
                var format = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: store.userCurrency,
                });
                return format.format(value);
            };
            return {
                formatPrice,
            };
        },
    });
</script>
