<template>
    <template v-if="data.housekeeping">
        <swiper-container :slides-per-view="1" :centered-slides="true" :navigation="true">
            <template v-for="housekeepingTask in data.housekeeping" :key="housekeepingTask">
                <swiper-slide
                    :style="
                        data.housekeeping.length > 1 ? 'padding: 10px 25px !important' : 'padding: 10px 0px !important'
                    "
                >
                    <ion-card class="ion-no-margin" style="margin: 0px 15px !important">
                        <ion-card-title
                            style="
                                display: flex;
                                align-items: center;
                                background: linear-gradient(118deg, #0d47a1, rgba(13, 71, 161, 0.7));
                                padding: 10px;
                            "
                        >
                            <div
                                style="
                                    width: 20px !important;
                                    height: 20px !important;
                                    display: flex;
                                    object-fit: contain;
                                "
                            >
                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fa"
                                    data-icon="hotel"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    class="svg-inline--fa fa-hotel fa-w-18 fa-3x"
                                    style="color: white; width: 20px !important; height: 20px !important"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M560 64c8.84 0 16-7.16 16-16V16c0-8.84-7.16-16-16-16H16C7.16 0 0 7.16 0 16v32c0 8.84 7.16 16 16 16h15.98v384H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h240v-80c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v80h240c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16h-16V64h16zm-304 44.8c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4zm0 96c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4zm-128-96c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4zM179.2 256h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4c0 6.4-6.4 12.8-12.8 12.8zM192 384c0-53.02 42.98-96 96-96s96 42.98 96 96H192zm256-140.8c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-96c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4z"
                                        class=""
                                    ></path>
                                </svg>
                            </div>
                            <ion-label style="font-size: 18px; font-weight: 600; color: white; margin-left: 10px">
                                {{ housekeepingTask.rental_property.name }}
                            </ion-label>
                        </ion-card-title>
                        <ion-card-content style="padding: 0px">
                            <ion-list lines="full">
                                <ion-item>
                                    <ion-select
                                        label="HOUSEKEEPER:"
                                        :value="assignedHousekeepers(housekeepingTask)"
                                        @ionChange="assignHousekeeper(housekeepingTask, $event.target.value)"
                                    >
                                        <ion-select-option :value="null">Not Assigned</ion-select-option>
                                        <ion-select-option
                                            v-for="cleaner in housekeepingTask.cleaner_options"
                                            :key="cleaner.id"
                                            :value="cleaner.id"
                                        >
                                            {{ cleaner.full_name }}
                                        </ion-select-option>
                                    </ion-select>
                                </ion-item>
                                <template v-if="housekeepingTask.house_keeping_job">
                                    <ion-item>
                                        <ion-label>CLEAN ON:</ion-label>
                                        <ion-datetime-button
                                            style="width: fit-content"
                                            datetime="datetime"
                                        ></ion-datetime-button>
                                        <ion-modal :keep-contents-mounted="true">
                                            <ion-datetime
                                                v-model="housekeepingTask.house_keeping_job.schedule_dt"
                                                id="datetime"
                                                presentation="date"
                                                @ionChange="
                                                    updateHousekeeper(
                                                        housekeepingTask,
                                                        'schedule_dt',
                                                        $event.target.value
                                                    )
                                                "
                                                :show-default-buttons="true"
                                                :min="cleanOnDateMinimum()"
                                            ></ion-datetime>
                                        </ion-modal>
                                    </ion-item>
                                    <ion-item>
                                        <ion-select
                                            label="TASK:"
                                            :value="housekeepingTask.house_keeping_job.job_type"
                                            @ionChange="
                                                updateHousekeeper(housekeepingTask, 'job_type', $event.target.value)
                                            "
                                        >
                                            <ion-select-option
                                                v-for="task in housekeepingTask.house_keeping_job.taskOptions"
                                                :key="task.value"
                                                :value="task.value"
                                            >
                                                {{ task.text }}
                                            </ion-select-option>
                                        </ion-select>
                                    </ion-item>
                                    <ion-item>
                                        <ion-select
                                            label="STATUS:"
                                            :value="housekeepingTask.house_keeping_job.job_status"
                                            @ionChange="
                                                updateHousekeeper(housekeepingTask, 'job_status', $event.target.value)
                                            "
                                        >
                                            <ion-select-option
                                                v-for="option in housekeepingTask.house_keeping_job.statusOptions"
                                                :key="option.value"
                                                :value="option.value"
                                            >
                                                {{ option.text }}
                                            </ion-select-option>
                                        </ion-select>
                                    </ion-item>

                                    <ion-item @click="editInstructions(housekeepingTask)" style="cursor: pointer">
                                        <ion-label>
                                            <h3>COMMENTS TO HOUSEKEEPER</h3>
                                            <p>
                                                {{ housekeepingTask.house_keeping_job.instructions || 'N/A' }}
                                            </p>
                                        </ion-label>
                                        <ion-icon
                                            :icon="pencil"
                                            slot="end"
                                            style="width: 20px; height: 20px"
                                        ></ion-icon>
                                    </ion-item>
                                    <ion-item>
                                        <ion-label>
                                            <h3>COMMENTS FROM HOUSEKEEPER</h3>
                                            <p>{{ housekeepingTask.house_keeping_job.comments || 'N/A' }}</p>
                                        </ion-label>
                                    </ion-item>
                                    <ion-item v-if="housekeepingTask.house_keeping_job.damage_notice">
                                        <ion-label>
                                            <h3>DAMAGE NOTICE</h3>
                                            <p>
                                                <span style="font-weight: 700">Description</span>:
                                                {{ housekeepingTask.house_keeping_job.damage_notice.description }}
                                            </p>
                                            <p>
                                                <span style="font-weight: 700">Photos Reported</span>:
                                                {{ housekeepingTask.house_keeping_job.damage_notice.photos.length }}
                                            </p>
                                            <p v-if="housekeepingTask.house_keeping_job.damage_notice.work_order_id">
                                                <span style="font-weight: 700">Work Order Created</span>
                                            </p>
                                        </ion-label>
                                        <ion-button
                                            v-if="housekeepingTask.house_keeping_job.damage_notice.work_order_id"
                                            @click="
                                                openWorkOrder(
                                                    housekeepingTask.house_keeping_job.damage_notice.work_order_id
                                                )
                                            "
                                            slot="end"
                                            style="
                                                max-width: 40px;
                                                max-height: 40px;
                                                height: 40px;
                                                width: 40px;
                                                --background: white;
                                            "
                                        >
                                            <DamageNoticeIcon style="fill: #0d47a1; width: 20px; height: 20px"
                                        /></ion-button>
                                    </ion-item>
                                </template>
                            </ion-list>
                        </ion-card-content>
                    </ion-card>
                </swiper-slide>
            </template>
        </swiper-container>
    </template>
</template>

<script lang="ts">
    import { defineComponent, ref } from 'vue';
    import AppFunctions from '@/services/AppFunctions';
    import { formatDateWithTime } from '@/utils/Date';
    import DamageNoticeIcon from '@/assets/icons/damageNoticeIcon.vue';
    import AppAPI from '@/services/AppAPI';
    import { basicInputAlert } from '@/services/AlertController';
    import { pencil } from 'ionicons/icons';

    export default defineComponent({
        components: {
            DamageNoticeIcon,
        },
        props: {
            data: {
                type: Object,
                required: true,
            },
            modalBaseLayoutRef: {
                type: Object,
                required: true,
            },
        },
        emits: ['fetchData'],
        setup(props, ctx) {
            const assignedHousekeepers = (housekeepingTask: any) => {
                let housekeepers = [null];
                if (housekeepingTask.house_keeping_job) {
                    for (let i = 0; i < housekeepingTask.house_keeping_job.house_keeper_ids.length; i++) {
                        const housekeeperId = housekeepingTask.house_keeping_job.house_keeper_ids[i];
                        const housekeeper = housekeepingTask.cleaner_options.find(
                            (cleaner: any) => cleaner.id == housekeeperId
                        );
                        if (housekeeper) {
                            housekeepers = housekeepers.filter((id) => id !== null);
                            housekeepers.push(housekeeperId);
                        }
                    }
                }
                return housekeepers;
            };

            const cleanOnDateMinimum = () => {
                const date = new Date(props.data.endDate).toISOString();
                return date;
            };

            const housekeepingTaskLabel = (housekeepingTask: any) => {
                const label = housekeepingTask.house_keeping_job.taskOptions.find(
                    (option: any) => option.value == housekeepingTask.house_keeping_job.job_type
                );
                return label ? label.text : 'Unknown';
            };

            const housekeepingStatusLabel = (housekeepingTask: any) => {
                const label = housekeepingTask.house_keeping_job.statusOptions.find(
                    (option: any) => option.value == housekeepingTask.house_keeping_job.job_status
                );
                return label ? label.text : 'Unknown';
            };

            const openWorkOrder = (workOrderId: string) => {
                if (!workOrderId) {
                    return;
                }
                AppFunctions.transitionClear('/workOrders?id=' + workOrderId);
                props.modalBaseLayoutRef.cancel();
            };

            const assignHousekeeper = async (housekeepingTask: any, selectedValues: any) => {
                const techResId = housekeepingTask.id;
                const data = {
                    house_keeper_ids: [selectedValues],
                };
                if (!selectedValues) {
                    data.house_keeper_ids = [];
                }
                await AppAPI.updateHousekeeping(techResId, data);
                ctx.emit('fetchData');
            };

            const updateHousekeeper = async (housekeepingTask: any, type: any, value: any) => {
                const techResId = housekeepingTask.id;
                const data: any = {
                    house_keeper_ids: housekeepingTask.house_keeping_job.house_keeper_ids,
                };
                data[type] = value;
                await AppAPI.updateHousekeeping(techResId, data);
                ctx.emit('fetchData');
            };

            const editInstructions = async (housekeepingTask: any) => {
                const response = await basicInputAlert(
                    'Comments to Housekeeper',
                    '',
                    '',
                    'Enter comments...',
                    housekeepingTask.house_keeping_job.instructions || ''
                );
                if (response.role === 'confirm') {
                    await updateHousekeeper(housekeepingTask, 'instructions', response.data.values.input);
                    ctx.emit('fetchData');
                }
            };

            return {
                pencil,
                housekeepingTaskLabel,
                housekeepingStatusLabel,
                openWorkOrder,
                formatDateWithTime,
                assignHousekeeper,
                assignedHousekeepers,
                updateHousekeeper,
                cleanOnDateMinimum,
                editInstructions,
            };
        },
    });
</script>
