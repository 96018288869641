<template>
    <base-layout page-title="View Guests" whiteBackground removeMode>
        <template #header>
            <div style="display: flex; align-items: center; padding: 2px 0">
                <ion-searchbar
                    placeholder="Search Guests"
                    :debounce="250"
                    @ionInput="search($event)"
                    style="padding: 0px 5px"
                ></ion-searchbar>
            </div>
        </template>

        <div style="margin-top: 2px">
            <ion-list v-for="(guest, index) in guests" :key="index" lines="full" class="ion-no-padding">
                <ion-item @click="ModalController.guestDetailsModal(guest.id)" style="cursor: pointer">
                    <div style="display: flex; flex-direction: column; width: 100%">
                        <div
                            style="
                                padding: 0px;
                                margin: 4px 0px;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                            "
                        >
                            <div v-if="guest.is_vip" style="display: flex; align-items: center">
                                <img src="@/assets/icons/vip.svg" class="status-icon" />
                            </div>
                            <div v-else-if="guest.is_blocklist" style="display: flex; align-items: center">
                                <img src="@/assets/icons/blocklist.svg" class="status-icon" />
                            </div>
                            <div style="font-size: 16px; font-weight: 500">
                                {{ guest.full_name }}
                            </div>
                        </div>
                        <div style="font-size: 14px; font-weight: 400; padding: 0px; margin: 0px 0px 5px">
                            {{ guest.email }}
                        </div>
                    </div>
                    <ion-icon slot="end" :icon="chevronForward" />
                </ion-item>
            </ion-list>
        </div>
        <div ref="endOfGuestsList"></div>

        <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin: 10px 0px">
            <ion-spinner v-if="loading" color="primary" name="circles"></ion-spinner>
        </div>

        <div
            v-if="guests.length <= 0 && !loading"
            style="
                height: 55%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            "
        >
            <ion-icon :icon="people" style="width: 55px; height: 55px" color="medium" />
            <ion-label>No Guests {{ searchValue != '' ? 'Found' : '' }}</ion-label>
        </div>
        <div
            v-if="guests.length >= totalGuests && guests.length"
            style="display: flex; justify-content: center; align-items: center"
        >
            <ion-label>No More Guests</ion-label>
        </div>

        <template #footer>
            <div style="display: flex; justify-content: center; align-items: center">
                <ion-label style="font-size: larger"
                    >{{ searchValue == '' ? 'Total' : 'Search Returned' }}: {{ totalGuests }} Guests</ion-label
                >
            </div>
        </template>
    </base-layout>
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref } from 'vue';
    import AppAPI from '@/services/AppAPI';
    import { errorToast } from '@/services/Toast';
    import ModalController from '@/services/ModalController';
    import { useStore } from '@/store/store';
    import { chevronForward, add, people, mail, call } from 'ionicons/icons';

    export default defineComponent({
        components: {},
        setup() {
            const ionRouter = useIonRouter();
            const store = useStore();
            const guests = ref([] as any[]);
            const totalGuests = ref(0);
            const endOfGuestsList = ref();
            const loading = ref(false);
            const searchValue = ref('');

            onMounted(async () => {
                await fetchGuests();
                createObserver();
            });

            const fetchGuests = async (reset = false) => {
                while (loading.value) {
                    await new Promise((resolve) => setTimeout(resolve, 50));
                }

                loading.value = true;
                try {
                    if (reset) {
                        guests.value = [];
                    }
                    const response = await AppAPI.getGuests(guests.value.length || 0, searchValue.value);
                    guests.value = [...guests.value, ...response.data.guests];
                    totalGuests.value = response.data.total || 0;
                } catch (e) {
                    errorToast('Error loading guests');
                } finally {
                    loading.value = false;
                }
            };

            const createObserver = () => {
                const observer = new IntersectionObserver(
                    async (entries) => {
                        entries.forEach(async (entry) => {
                            if (entry.isIntersecting) {
                                if (guests.value.length < totalGuests.value) {
                                    await fetchGuests();
                                    observer.disconnect();
                                    if (endOfGuestsList.value) {
                                        observer.observe(endOfGuestsList.value);
                                    }
                                }
                            }
                        });
                    },
                    {
                        threshold: 1.0,
                    }
                );
                if (endOfGuestsList.value) {
                    observer.observe(endOfGuestsList.value);
                }
            };

            const search = async (value: any) => {
                searchValue.value = value.detail.value;
                await fetchGuests(true);
            };

            return {
                ionRouter,
                store,
                guests,
                totalGuests,
                endOfGuestsList,
                loading,
                chevronForward,
                add,
                searchValue,
                people,
                search,
                ModalController,
                mail,
                call,
            };
        },
    });
</script>

<style scoped>
    .footer {
        text-align: center;
        padding: 10px 0;
        position: fixed;
        bottom: 0;
        z-index: 99;
        width: 100%;
        overflow-x: auto !important;
        overflow-y: hidden;
        background: white;
        box-shadow: 0px -1px 5px 0px rgba(194, 194, 194, 1);
    }
    .header {
        background: white;
        box-shadow: 0px 1px 5px 0px rgba(194, 194, 194, 1);
        position: fixed;
        z-index: 99;
        width: 100%;
        height: 62px;
    }

    .status-icon {
        height: 16px;
        width: 16px;
        margin-right: 5px;
    }
</style>
