import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "truncate",
  style: {"text-transform":"uppercase","font-weight":"400","font-size":"small","margin-bottom":"10px"}
}
const _hoisted_2 = {
  key: 0,
  class: "truncate",
  style: {"display":"flex","align-items":"center"}
}
const _hoisted_3 = {
  key: 1,
  class: "truncate",
  style: {"display":"flex","align-items":"center"}
}
const _hoisted_4 = {
  key: 2,
  class: "truncate",
  style: {"display":"flex","align-items":"center"}
}
const _hoisted_5 = {
  key: 1,
  style: {"display":"flex","flex-direction":"column","width":"100%","justify-content":"center","align-items":"center","height":"50vh"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, { title: "Vendors" }, {
    footer: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "medium",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel()))
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Close")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_ctx.vendors.length > 0)
        ? (_openBlock(), _createBlock(_component_ion_list, {
            key: 0,
            lines: "full",
            style: {"padding-bottom":"60px"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vendors, (vendor, index) => {
                return (_openBlock(), _createBlock(_component_ion_item, { key: index }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createElementVNode("h2", null, _toDisplayString(vendor.id) + ": " + _toDisplayString(vendor.company), 1),
                        _createElementVNode("p", _hoisted_1, _toDisplayString(vendor.specialty), 1),
                        (vendor.first_name || vendor.last_name)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
                              _createVNode(_component_ion_icon, {
                                style: {"margin-right":"5px"},
                                src: _ctx.person
                              }, null, 8, ["src"]),
                              _createTextVNode(_toDisplayString(vendor.first_name) + " " + _toDisplayString(vendor.last_name), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (vendor.email)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                              _createVNode(_component_ion_icon, {
                                style: {"margin-right":"5px"},
                                src: _ctx.mail
                              }, null, 8, ["src"]),
                              _createTextVNode(_toDisplayString(vendor.email), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (vendor.mobile_phone)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                              _createVNode(_component_ion_icon, {
                                style: {"margin-right":"5px"},
                                src: _ctx.call
                              }, null, 8, ["src"]),
                              _createTextVNode(_toDisplayString(vendor.mobile_phone), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_ion_icon, {
              src: _ctx.personCircle,
              style: {"width":"50px","height":"50px","margin-bottom":"10px"}
            }, null, 8, ["src"]),
            _createVNode(_component_ion_label, { style: {"font-size":"large"} }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("No Vendors Found")
              ])),
              _: 1
            })
          ]))
    ]),
    _: 1
  }))
}