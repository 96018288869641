import { useStore } from '@/store/store';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import AppFunctions from './AppFunctions';
import { Intercom } from '@capacitor-community/intercom';
// import { Diagnostic } from '@awesome-cordova-plugins/diagnostic';
import ModalController from '@/services/ModalController';
import { infoToast } from './Toast';
import { accessToken } from './OAuth2';
import { SplashScreen } from '@capacitor/splash-screen';

function setupFCM() {
    const store = useStore();
    if (Capacitor.getPlatform() === 'web') {
        return;
    }

    if (store.PushNotificationToken != '') {
        return;
    }

    if (accessToken.value) {
        // Request permission to use push notifications
        registerNotifications();

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration', async (token: Token) => {
            store.changePushNotificationToken(token.value);
            //alert('Push registration success, token: ' + token.value);
        });

        PushNotifications.addListener('registrationError', (error: any) => {
            //alert('Error on registration: ' + JSON.stringify(error));
        });

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
            //console.log('Push received: ' + JSON.stringify(notification));
            if (store.activePage != '/unifiedInbox') {
                infoToast('Alert: New Notification!');
            }
            //PushNotifications.removeAllDeliveredNotifications();
            if (Capacitor.getPlatform() === 'android') {
                Intercom.receivePush(notification.data);
            }
            AppFunctions.updatePushNotificationSystem();
        });
    }
}

async function registerNotifications() {
    const store = useStore();
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
    }

    await PushNotifications.register();
}

async function getNotificaitons() {
    if (Capacitor.getPlatform() === 'web') {
        return;
    }
    AppFunctions.updatePushNotificationSystem();
}

async function addPushNotificationTapOpen() {
    const store = useStore();
    if (Capacitor.getPlatform() === 'web') {
        return;
    }

    //tap on notification to open app
    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
        const data = notification.notification.data;
        if (data) {
            const inputValue = notification.inputValue || '';
            data.inputValue = inputValue;
            handlePushNotificationOpen(data);
        }
    });
}

async function handlePushNotificationOpen(data: any) {
    const store = useStore();

    if (data.inputValue) {
        return;
    }

    if (data.conversation_id) {
        AppFunctions.openChat(data.conversation_id, data.message_id);
    } else {
        await AppFunctions.updatePushNotificationSystem();
        const notificationList = store.PushNotificationList;
        for (let i = 0; i < notificationList.length; i++) {
            if (notificationList[i].id == data.notification_id) {
                await ModalController.notificationModal(notificationList[i]);
                i = notificationList.length + 1;
                return;
            }
        }
    }
}

export { setupFCM, getNotificaitons, addPushNotificationTapOpen, handlePushNotificationOpen };
