<template>
    <ion-list lines="full">
        <ion-item v-for="(item, index) in data.properties" :key="index">
            <ion-label>Property {{ isMultipleProperties ? index + 1 : '' }}</ion-label>
            <ion-label slot="end" style="text-align: end" v-if="item.room_id"
                >{{ item.name }} - {{ item.room_id }}</ion-label
            >
            <ion-label slot="end" style="text-align: end" v-if="!item.room_id">{{ item.name }}</ion-label>
        </ion-item>
        <ion-item>
            <ion-label>Adults</ion-label>
            <ion-label slot="end" style="text-align: end">{{ data.guests }}</ion-label>
        </ion-item>
        <ion-item>
            <ion-label>Children</ion-label>
            <ion-label slot="end" style="text-align: end">{{ data.children }}</ion-label>
        </ion-item>

        <ion-item>
            <ion-label>Pets</ion-label>
            <ion-label slot="end" style="text-align: end">{{ reservationNumberOfPetsTotal }}</ion-label>
        </ion-item>

        <ion-item>
            <ion-label>Arrival</ion-label>
            <ion-label slot="end" style="text-align: end"
                >{{ formatDate(data.startDate) }}
                <span v-if="data.checkInTime">• {{ formatTime(data.checkInTime) }}</span></ion-label
            >
        </ion-item>
        <ion-item>
            <ion-label>Departure</ion-label>
            <ion-label slot="end" style="text-align: end"
                >{{ formatDate(data.endDate) }}
                <span v-if="data.checkOutTime">• {{ formatTime(data.checkOutTime) }}</span></ion-label
            >
        </ion-item>
        <ion-item>
            <ion-label>
                <h3>Invoice Notes</h3>
                <p style="color: black">{{ tmpNotes || 'N/A' }}</p>
            </ion-label>
            <ion-button
                @click="updateNotes()"
                slot="end"
                style="max-width: 40px; max-height: 40px; height: 40px; width: 40px"
                ><ion-icon :src="pencilOutline"></ion-icon
            ></ion-button>
        </ion-item>
    </ion-list>
</template>

<script lang="ts">
    import { computed, defineComponent } from 'vue';
    import { pencilOutline } from 'ionicons/icons';
    import { format } from 'date-fns';
    import ModalController from '@/services/ModalController';
    import AppAPI from '@/services/AppAPI';
    import { errorToast, successToast } from '@/services/Toast';

    export default defineComponent({
        props: {
            data: {
                type: Object,
                required: true,
            },
            isMultipleProperties: {
                type: Boolean,
                required: true,
            },
            tmpNotes: {
                type: String,
                required: true,
            },
            invoiceNumber: {
                type: String,
                required: true,
            },
        },
        emits: ['fetchData'],
        setup(props, ctx) {
            const reservationNumberOfPetsTotal = computed(() => {
                let total = 0;
                if (props.data.properties) {
                    props.data.properties.forEach((property: any) => {
                        total += property.number_of_pets;
                    });
                }
                return total;
            });

            const formatDate = (date: string) => {
                if (!date) {
                    return '';
                }
                let tmpDateArray = date.split('-');
                if (tmpDateArray.length <= 1) {
                    return date;
                }
                const month = tmpDateArray[1];
                const day = tmpDateArray[2];
                const year = tmpDateArray[0];

                let fromDateString = month + '/' + day + '/' + year;

                return fromDateString;
            };

            const formatTime = (time: string) => {
                try {
                    time = '2021-01-01T' + time;
                    time = format(new Date(time), 'h:mm a');
                } catch (e) {
                    time = '';
                }
                return time;
            };

            const updateNotes = async () => {
                const result = await ModalController.editInvoiceNotesModal(props.data.notes);
                if (result) {
                    if (result.notes == props.data.notes) {
                        return;
                    }

                    try {
                        const response = await AppAPI.updateInvoiceNotes(props.invoiceNumber, result.notes);
                        if (response) {
                            successToast('Invoice notes saved');
                        }
                    } catch (e) {
                        console.error(e);
                        errorToast('Error saving invoice notes');
                    } finally {
                        ctx.emit('fetchData');
                    }
                }
            };

            return {
                reservationNumberOfPetsTotal,
                pencilOutline,
                formatDate,
                formatTime,
                updateNotes,
            };
        },
    });
</script>
