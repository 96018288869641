<template>
    <modal-base-layout title="Material Expense">
        <div>
            <ion-list>
                <ion-item style="margin-right: 15px">
                    <label>Date</label>

                    <ion-datetime-button slot="end" datetime="dateMaterialExpense"></ion-datetime-button>
                    <ion-modal :keep-contents-mounted="true">
                        <ion-datetime
                            id="dateMaterialExpense"
                            presentation="date"
                            v-model="date"
                            :format-options="formatOptions"
                            :show-default-buttons="true"
                        ></ion-datetime>
                    </ion-modal>
                </ion-item>
                <ion-item lines="none">
                    <ion-textarea
                        autoGrow
                        v-model="description"
                        labelPlacement="floating"
                        label="Description"
                        :counter="true"
                        maxlength="128"
                    ></ion-textarea>
                </ion-item>
                <ion-item style="margin-right: 15px">
                    <ion-input v-model="quantity" type="number" label="Quantity" labelPlacement="floating"></ion-input>
                </ion-item>
                <ion-item style="margin-right: 15px">
                    <label>Price</label>
                    <CurrencyInput
                        slot="end"
                        v-model="price"
                        :options="{ currency: store.userCurrency }"
                    ></CurrencyInput>
                </ion-item>
            </ion-list>
        </div>

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="medium" @click="cancel()">Cancel</ion-button>
                    </ion-col>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="primary" @click="confirm()">Save</ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController } from '@ionic/vue';
    import { arrowBackOutline } from 'ionicons/icons';
    import { computed, defineComponent, onMounted, ref, watch } from 'vue';
    import { format } from 'date-fns';
    import { useStore } from '@/store/store';
    import AppAPI from '@/services/AppAPI';
    import { hideLoading, showLoading } from '@/services/Loading';
    import { errorToast } from '@/services/Toast';
    import AppFunctions from '@/services/AppFunctions';
    import CurrencyInput from '@/components/CurrencyInput.vue';

    export default defineComponent({
        components: {
            CurrencyInput,
        },
        props: {
            workOrderId: {
                type: Number,
                required: true,
            },
            data: {
                type: Object,
                required: false,
            },
        },
        setup(props) {
            const store = useStore();
            const date = ref(new Date().toISOString());
            const description = ref('');
            const quantity = ref(0);
            const price = ref(0);
            const formatOptions = {
                date: {
                    weekday: 'short',
                    month: 'long',
                    day: '2-digit',
                },
            };

            const cancel = () => {
                return modalController.dismiss(null, 'cancel');
            };

            const formatDate = (date: string) => {
                return format(new Date(date), 'yyyy-MM-dd');
            };

            const confirm = async () => {
                if (!props.workOrderId) {
                    let id = props.data?.id;
                    if (!id) {
                        id = await AppFunctions.uuid();
                    }
                    const data = {
                        date: formatDate(date.value),
                        description: description.value,
                        price: price.value,
                        qty: quantity.value,
                        id: id,
                    };
                    return modalController.dismiss(data, 'confirm');
                }

                if ((!quantity.value && quantity.value != 0) || (!price.value && price.value != 0) || !date.value) {
                    let missingFields = [] as string[];

                    if (!quantity.value && quantity.value != 0) {
                        missingFields.push('quantity');
                    }
                    if (!price.value && price.value != 0) {
                        missingFields.push('price');
                    }
                    if (!date.value) {
                        missingFields.push('date');
                    }

                    errorToast('Please enter ' + missingFields.join(', ') + '.');
                    return;
                }

                const loadingService = await showLoading('Loading...');

                try {
                    const data = {
                        date: formatDate(date.value),
                        description: description.value,
                        price: price.value,
                        qty: quantity.value,
                        work_order_pk: props.workOrderId,
                    };

                    if (props.data) {
                        await AppAPI.updateWorkOrderMaterialExpense(data, props.data.id);
                    } else {
                        await AppAPI.createWorkOrderMaterialExpense(data);
                    }
                    hideLoading(loadingService);
                } catch (error) {
                    console.log(error);
                    hideLoading(loadingService);
                }

                return modalController.dismiss({}, 'confirm');
            };

            onMounted(async () => {
                if (props.data) {
                    let [year, month, day] = props.data.date.split('-');
                    date.value = new Date(Number(year), Number(month) - 1, Number(day)).toISOString();
                    description.value = props.data.description;
                    quantity.value = props.data.qty;
                    price.value = props.data.price;
                }
            });

            return {
                arrowBackOutline,
                store,
                date,
                description,
                quantity,
                price,
                formatDate,
                cancel,
                confirm,
                format,
                formatOptions,
            };
        },
    });
</script>

<style scoped>
    ion-item {
        --background: transparent;
    }
    ion-list {
        background: transparent;
    }

    .image-wrapper {
        display: flex;
        overflow-x: auto;
    }

    .image-item {
        flex: 0 0 auto; /* Ensure items don't grow beyond their intrinsic size */
        min-width: 0; /* Allow items to shrink beyond their content size */
        overflow: hidden; /* Allow content to be clipped if it overflows */
        margin: 5px;
    }
    .custom-calendar .vc-header .vc-arrow {
        color: #000;
        background: white;
    }

    .custom-calendar .vc-header .vc-title {
        color: #000;
        background: white;
    }
</style>
