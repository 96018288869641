import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  slot: "content",
  style: {"padding-left":"15px"}
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { style: {"padding-left":"10px"} }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { style: {"padding-left":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!

  return (_ctx.isMultipleProperties)
    ? (_openBlock(), _createBlock(_component_ion_accordion_group, {
        key: 0,
        multiple: true
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.triggers, (trigger, index) => {
            return (_openBlock(), _createBlock(_component_ion_accordion, {
              key: index,
              value: index
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_item, { slot: "header" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(trigger.trigger_title), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createElementVNode("div", _hoisted_1, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(trigger.sub_triggers, (item) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      key: item.id_trigger,
                      lines: "full"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createElementVNode("h2", null, _toDisplayString(item.property_name), 1),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.timestamps, (timestamp) => {
                              return (_openBlock(), _createElementBlock("p", { key: timestamp }, _toDisplayString(timestamp), 1))
                            }), 128)),
                            (item.timestamps.length <= 0)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_2, "Not Sent"))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("div", null, [
                          _createVNode(_component_ion_button, {
                            onClick: ($event: any) => (_ctx.sendTrigger(item)),
                            size: "small"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                slot: "start",
                                src: _ctx.paperPlaneOutline
                              }, null, 8, ["src"]),
                              _createTextVNode(_toDisplayString(item.timestamps.length > 0 ? 'Resend' : 'Send'), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_ion_toggle, {
                            disabled: !item.can_change_is_active,
                            modelValue: item.is_active,
                            "onUpdate:modelValue": ($event: any) => ((item.is_active) = $event),
                            onIonChange: ($event: any) => (_ctx.toggleTrigger(item))
                          }, null, 8, ["disabled", "modelValue", "onUpdate:modelValue", "onIonChange"])
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ])
              ]),
              _: 2
            }, 1032, ["value"]))
          }), 128))
        ]),
        _: 1
      }))
    : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.triggers, (trigger, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          (trigger.id_tech_reservation)
            ? (_openBlock(), _createBlock(_component_ion_item, {
                key: 0,
                lines: "full"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createElementVNode("h2", null, _toDisplayString(trigger.trigger_title), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(trigger.timestamps, (timestamp) => {
                        return (_openBlock(), _createElementBlock("p", { key: timestamp }, _toDisplayString(timestamp), 1))
                      }), 128)),
                      (trigger.timestamps.length <= 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_4, "Not Sent"))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024),
                  _createElementVNode("div", null, [
                    _createVNode(_component_ion_button, {
                      onClick: ($event: any) => (_ctx.sendTrigger(trigger)),
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "start",
                          src: _ctx.paperPlaneOutline
                        }, null, 8, ["src"]),
                        _createTextVNode(_toDisplayString(trigger.timestamps.length > 0 ? 'Resend' : 'Send'), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_ion_toggle, {
                      disabled: !trigger.can_change_is_active,
                      modelValue: trigger.is_active,
                      "onUpdate:modelValue": ($event: any) => ((trigger.is_active) = $event),
                      onIonChange: ($event: any) => (_ctx.toggleTrigger(trigger))
                    }, null, 8, ["disabled", "modelValue", "onUpdate:modelValue", "onIonChange"])
                  ])
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
}