import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = {
  key: 1,
  style: {"display":"flex","flex-direction":"row","align-items":"center","border-bottom":"0.25px solid rgb(196, 195, 195)"}
}
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  slot: "end"
}
const _hoisted_6 = {
  key: 0,
  slot: "end"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { style: {"max-width":"400px","height":"fit-content","margin":"0 10px"} }
const _hoisted_9 = { style: {"position":"relative"} }
const _hoisted_10 = ["src", "onClick"]
const _hoisted_11 = {
  key: 0,
  style: {"background-color":"white","border":"1px solid lightgray","border-top":"none","border-bottom-left-radius":"10px","border-bottom-right-radius":"10px","padding":"5px"}
}
const _hoisted_12 = { style: {"max-width":"400px","height":"fit-content","margin":"0 10px"} }
const _hoisted_13 = { style: {"position":"relative"} }
const _hoisted_14 = ["src", "onClick"]
const _hoisted_15 = {
  key: 0,
  style: {"background-color":"white","border":"1px solid lightgray","border-top":"none","border-bottom-left-radius":"10px","border-bottom-right-radius":"10px","padding":"5px"}
}
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { style: {"display":"flex","flex-direction":"column","padding":"10px","width":"100%"} }
const _hoisted_18 = {
  slot: "end",
  style: {"display":"flex","flex-direction":"column"}
}
const _hoisted_19 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_datetime_button = _resolveComponent("ion-datetime-button")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_CurrencyInput = _resolveComponent("CurrencyInput")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper_container = _resolveComponent("swiper-container")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, {
    title: _ctx.workOrderId ? 'Work Order #' + _ctx.workOrderDetails?.number : 'Create Work Order',
    background: "rgb(239, 246, 255)"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    class: "modal-footer-button",
                    color: "medium",
                    onClick: _cache[39] || (_cache[39] = ($event: any) => (_ctx.cancel()))
                  }, {
                    default: _withCtx(() => _cache[76] || (_cache[76] = [
                      _createTextVNode("Cancel")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  (!_ctx.store.workOrderPermissions.is_modify_disabled && _ctx.workOrderId)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        class: "modal-footer-button",
                        color: "primary",
                        onClick: _cache[40] || (_cache[40] = ($event: any) => (_ctx.confirm()))
                      }, {
                        default: _withCtx(() => _cache[77] || (_cache[77] = [
                          _createTextVNode("Save")
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.store.workOrderPermissions.has_add_access && !_ctx.workOrderId)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        class: "modal-footer-button",
                        color: "primary",
                        onClick: _cache[41] || (_cache[41] = ($event: any) => (_ctx.createWorkOrder()))
                      }, {
                        default: _withCtx(() => _cache[78] || (_cache[78] = [
                          _createTextVNode("Create")
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_ctx.workOrderDetails)
        ? (_openBlock(), _createBlock(_component_ion_list, {
            key: 0,
            lines: "full"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_select, {
                      modelValue: _ctx.status,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.status) = $event)),
                      placeholder: "Not Set",
                      label: "Status"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.workOrderFormOptions.status_choices, (status) => {
                          return (_openBlock(), _createElementBlock(_Fragment, {
                            key: status.id
                          }, [
                            (
                                    (status.id == 221 && !_ctx.store.workOrderPermissions.is_close_disabled) ||
                                    status.id != 221
                                )
                              ? (_openBlock(), _createBlock(_component_ion_select_option, {
                                  key: 0,
                                  value: status.id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(status.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_select, {
                      modelValue: _ctx.property,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.property) = $event)),
                      placeholder: "Not Set",
                      label: "Property"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.workOrderFormProperties, (property) => {
                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                            key: property.id,
                            value: property.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(property.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, {
                  lines: _ctx.reporterType && _ctx.reporterType != 'SUBSCRIBER' ? 'none' : 'full'
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_select, {
                      modelValue: _ctx.reporterType,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.reporterType) = $event)),
                      label: "Reported By"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_select_option, { value: "SUBSCRIBER" }, {
                          default: _withCtx(() => _cache[42] || (_cache[42] = [
                            _createTextVNode("Subscriber")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ion_select_option, { value: "TEAM_MEMBER" }, {
                          default: _withCtx(() => _cache[43] || (_cache[43] = [
                            _createTextVNode("Team Member")
                          ])),
                          _: 1
                        }),
                        (_ctx.workOrderOwners?.length > 0)
                          ? (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: 0,
                              value: "OWNER"
                            }, {
                              default: _withCtx(() => _cache[44] || (_cache[44] = [
                                _createTextVNode("Owner")
                              ])),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_ion_select_option, { value: "OTHER" }, {
                          default: _withCtx(() => _cache[45] || (_cache[45] = [
                            _createTextVNode("Other")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["lines"]),
                (_ctx.reporterType && _ctx.reporterType != 'SUBSCRIBER')
                  ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                      default: _withCtx(() => [
                        (_ctx.reporterType == 'TEAM_MEMBER')
                          ? (_openBlock(), _createBlock(_component_ion_select, {
                              key: 0,
                              placeholder: "",
                              modelValue: _ctx.reporter,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.reporter) = $event)),
                              label: ""
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.WorkOrderTeamMembers, (member) => {
                                  return (_openBlock(), _createBlock(_component_ion_select_option, {
                                    key: member.id,
                                    value: member.id
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(member.first_name + ' ' + member.last_name), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["modelValue"]))
                          : _createCommentVNode("", true),
                        (_ctx.reporterType == 'OWNER')
                          ? (_openBlock(), _createBlock(_component_ion_select, {
                              key: 1,
                              placeholder: "",
                              modelValue: _ctx.reporter,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.reporter) = $event)),
                              label: ""
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workOrderOwners, (owner) => {
                                  return (_openBlock(), _createBlock(_component_ion_select_option, {
                                    key: owner.id,
                                    value: owner.id
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(owner.first_name + ' ' + owner.last_name), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["modelValue"]))
                          : _createCommentVNode("", true),
                        (_ctx.reporterType == 'OTHER')
                          ? (_openBlock(), _createBlock(_component_ion_input, {
                              key: 2,
                              placeholder: "Reported By Email",
                              label: "Email",
                              labelPlacement: "floating",
                              modelValue: _ctx.reporter,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.reporter) = $event))
                            }, null, 8, ["modelValue"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                false
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_ion_label, { style: {"width":"100%","margin-left":"15px"} }, {
                        default: _withCtx(() => _cache[46] || (_cache[46] = [
                          _createTextVNode("Reported By")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_ion_select, {
                        style: {"width":"fit-content","margin-right":"15px"},
                        modelValue: _ctx.reporterType,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.reporterType) = $event)),
                        label: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select_option, { value: "SUBSCRIBER" }, {
                            default: _withCtx(() => _cache[47] || (_cache[47] = [
                              _createTextVNode("Subscriber")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_ion_select_option, { value: "TEAM_MEMBER" }, {
                            default: _withCtx(() => _cache[48] || (_cache[48] = [
                              _createTextVNode("Team Member")
                            ])),
                            _: 1
                          }),
                          (_ctx.workOrderOwners?.length > 0)
                            ? (_openBlock(), _createBlock(_component_ion_select_option, {
                                key: 0,
                                value: "OWNER"
                              }, {
                                default: _withCtx(() => _cache[49] || (_cache[49] = [
                                  _createTextVNode("Owner")
                                ])),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_ion_select_option, { value: "OTHER" }, {
                            default: _withCtx(() => _cache[50] || (_cache[50] = [
                              _createTextVNode("Other")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"]),
                      (_ctx.reporterType == 'TEAM_MEMBER')
                        ? (_openBlock(), _createBlock(_component_ion_select, {
                            key: 0,
                            style: {"width":"fit-content","margin-right":"15px"},
                            placeholder: "",
                            modelValue: _ctx.reporter,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.reporter) = $event)),
                            label: ""
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.WorkOrderTeamMembers, (member) => {
                                return (_openBlock(), _createBlock(_component_ion_select_option, {
                                  key: member.id,
                                  value: member.id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(member.first_name + ' ' + member.last_name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"]))
                        : _createCommentVNode("", true),
                      (_ctx.reporterType == 'OWNER')
                        ? (_openBlock(), _createBlock(_component_ion_select, {
                            key: 1,
                            style: {"width":"fit-content","margin-right":"15px"},
                            placeholder: "",
                            modelValue: _ctx.reporter,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.reporter) = $event)),
                            label: ""
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workOrderOwners, (owner) => {
                                return (_openBlock(), _createBlock(_component_ion_select_option, {
                                  key: owner.id,
                                  value: owner.id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(owner.first_name + ' ' + owner.last_name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"]))
                        : _createCommentVNode("", true),
                      (_ctx.reporterType == 'OTHER')
                        ? (_openBlock(), _createBlock(_component_ion_input, {
                            key: 2,
                            style: {"width":"fit-content","margin-right":"15px"},
                            placeholder: "Enter Email Here",
                            modelValue: _ctx.reporter,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.reporter) = $event))
                          }, null, 8, ["modelValue"]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_select, {
                      modelValue: _ctx.accountAssignee,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.accountAssignee) = $event)),
                      placeholder: "Not Set",
                      label: "Who Will Pay"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_select_option, {
                          value: _ctx.workOrderDetails.subscriber || 'SUBSCRIBER'
                        }, {
                          default: _withCtx(() => _cache[51] || (_cache[51] = [
                            _createTextVNode("Subscriber")
                          ])),
                          _: 1
                        }, 8, ["value"]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workOrderOwners, (owner) => {
                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                            key: owner.id,
                            value: owner.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(owner.first_name + ' ' + owner.last_name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, {
                  lines: _ctx.damageNoticeSource ? 'full' : 'none'
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_select, {
                      label: "Account",
                      modelValue: _ctx.account,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.account) = $event))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.WorkOrderExpenseAccoutns, (expenseAccount) => {
                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                            key: expenseAccount,
                            value: expenseAccount.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(expenseAccount.number) + " - " + _toDisplayString(expenseAccount.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["lines"]),
                (_ctx.damageNoticeSource && _ctx.reservationAccess())
                  ? (_openBlock(), _createBlock(_component_ion_item, {
                      key: 2,
                      lines: "none"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { style: {"font-family":"'San Francisco', sans-serif","font-size":"15px","font-weight":"500"} }, {
                          default: _withCtx(() => _cache[52] || (_cache[52] = [
                            _createTextVNode("Source")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ion_button, {
                          style: {"padding":"10px 0"},
                          slot: "end",
                          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.openInvoice()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Damage Notice - Inv #" + _toDisplayString(_ctx.damageNoticeSource.meta_reservation_id), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_ion_segment, {
                  modelValue: _ctx.segmentValue,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.segmentValue) = $event)),
                  mode: "ios",
                  style: {"border-radius":"8px 8px 0 0","height":"50px"},
                  scrollable: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_segment_button, { value: "problem" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => _cache[53] || (_cache[53] = [
                            _createTextVNode("Problem")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_segment_button, { value: "materials-and-expenses" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => _cache[54] || (_cache[54] = [
                            _createTextVNode("Materials & Expenses")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_segment_button, { value: "internal-notes" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => _cache[55] || (_cache[55] = [
                            _createTextVNode("Internal Notes")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                (_ctx.segmentValue == 'problem')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select, {
                            label: "Job Type",
                            modelValue: _ctx.jobType,
                            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.jobType) = $event)),
                            placeholder: "Not Set"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.workOrderJobTypes, (jobType) => {
                                return (_openBlock(), _createBlock(_component_ion_select_option, {
                                  key: jobType.id,
                                  value: jobType.id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(jobType.title), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select, {
                            label: "Priority",
                            modelValue: _ctx.priority,
                            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.priority) = $event)),
                            placeholder: "Not Set"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.workOrderFormOptions.priority_choices, (priority) => {
                                return (_openBlock(), _createBlock(_component_ion_select_option, {
                                  key: priority.id,
                                  value: priority.id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(priority.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, { lines: "full" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { style: {"font-family":"'San Francisco', sans-serif","font-size":"15px","font-weight":"500"} }, {
                            default: _withCtx(() => _cache[56] || (_cache[56] = [
                              _createTextVNode("Date Reported")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_ion_datetime_button, { datetime: "dateReported" }),
                          _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_datetime, {
                                id: "dateReported",
                                presentation: "date",
                                modelValue: _ctx.workOrderDetails.date_reported,
                                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.workOrderDetails.date_reported) = $event)),
                                "format-options": _ctx.formatOptions,
                                "show-default-buttons": true
                              }, null, 8, ["modelValue", "format-options"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_textarea, {
                            autoGrow: "",
                            style: {"margin-bottom":"10px"},
                            modelValue: _ctx.workOrderDescription,
                            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.workOrderDescription) = $event)),
                            placeholder: "No Description Added",
                            label: "Description",
                            labelPlacement: "floating"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, { lines: "full" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { style: {"font-family":"'San Francisco', sans-serif","font-size":"15px","font-weight":"500"} }, {
                            default: _withCtx(() => _cache[57] || (_cache[57] = [
                              _createTextVNode("Start Date")
                            ])),
                            _: 1
                          }),
                          (_ctx.workOrderDetails.start_date)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createVNode(_component_ion_datetime_button, { datetime: "dateStarted" }),
                                _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_datetime, {
                                      id: "dateStarted",
                                      presentation: "date",
                                      modelValue: _ctx.workOrderDetails.start_date,
                                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.workOrderDetails.start_date) = $event)),
                                      "format-options": _ctx.formatOptions,
                                      "show-default-buttons": true
                                    }, null, 8, ["modelValue", "format-options"])
                                  ]),
                                  _: 1
                                })
                              ]))
                            : (_openBlock(), _createBlock(_component_ion_button, {
                                key: 1,
                                color: "primary",
                                style: {"padding":"10px 0"},
                                slot: "end",
                                onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.workOrderDetails.start_date = _ctx.getTodayDate()))
                              }, {
                                default: _withCtx(() => _cache[58] || (_cache[58] = [
                                  _createTextVNode("Start Task")
                                ])),
                                _: 1
                              }))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, { lines: "full" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { style: {"font-family":"'San Francisco', sans-serif","font-size":"15px","font-weight":"500"} }, {
                            default: _withCtx(() => _cache[59] || (_cache[59] = [
                              _createTextVNode("Completed Date")
                            ])),
                            _: 1
                          }),
                          (_ctx.workOrderDetails.completed_date)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createVNode(_component_ion_datetime_button, { datetime: "dateCompleted" }),
                                _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_datetime, {
                                      id: "dateCompleted",
                                      presentation: "date",
                                      modelValue: _ctx.workOrderDetails.completed_date,
                                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.workOrderDetails.completed_date) = $event)),
                                      "format-options": _ctx.formatOptions,
                                      "show-default-buttons": true
                                    }, null, 8, ["modelValue", "format-options"])
                                  ]),
                                  _: 1
                                })
                              ]))
                            : (_openBlock(), _createBlock(_component_ion_button, {
                                key: 1,
                                style: {"padding":"10px 0"},
                                color: "primary",
                                slot: "end",
                                onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.workOrderDetails.completed_date = _ctx.getTodayDate()))
                              }, {
                                default: _withCtx(() => _cache[60] || (_cache[60] = [
                                  _createTextVNode("Mark Complete")
                                ])),
                                _: 1
                              }))
                        ]),
                        _: 1
                      }),
                      (_ctx.workOrderDetails.completed_date)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_ion_item, { lines: "full" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { style: {"font-family":"'San Francisco', sans-serif","font-size":"15px","font-weight":"500"} }, {
                                  default: _withCtx(() => _cache[61] || (_cache[61] = [
                                    _createTextVNode("Amount")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_CurrencyInput, {
                                  modelValue: _ctx.workOrderDetails.amount,
                                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.workOrderDetails.amount) = $event)),
                                  options: { currency: _ctx.store.userCurrency },
                                  placeholder: "Enter Amount"
                                }, null, 8, ["modelValue", "options"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, { lines: "none" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_input, {
                                  labelPlacement: "start",
                                  placeholder: "Enter Invoice #",
                                  style: {"text-align":"end"},
                                  label: "Vendor Invoice #",
                                  modelValue: _ctx.vendorInvoiceNumber,
                                  "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.vendorInvoiceNumber) = $event)),
                                  onInput: _ctx.vendorInvoiceNumberCheck
                                }, null, 8, ["modelValue", "onInput"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_select, {
                                  placeholder: "Not Set",
                                  modelValue: _ctx.vendorInvoiceVendorId,
                                  "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.vendorInvoiceVendorId) = $event)),
                                  label: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_select_option, { value: "" }, {
                                      default: _withCtx(() => _cache[62] || (_cache[62] = [
                                        _createTextVNode("Select Vendor")
                                      ])),
                                      _: 1
                                    }),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.workOrderVendors, (vendor) => {
                                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                                        key: vendor.id,
                                        value: vendor.id
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(vendor.company), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, { lines: "none" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_input, {
                                  labelPlacement: "start",
                                  placeholder: "Enter Receipt #",
                                  style: {"text-align":"end"},
                                  label: "Store Receipt #",
                                  modelValue: _ctx.receiptNumber,
                                  "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.receiptNumber) = $event)),
                                  onInput: _ctx.receiptNumberCheck
                                }, null, 8, ["modelValue", "onInput"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_select, {
                                  placeholder: "Not Set",
                                  modelValue: _ctx.receiptStoreId,
                                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.receiptStoreId) = $event)),
                                  label: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_select_option, { value: "" }, {
                                      default: _withCtx(() => _cache[63] || (_cache[63] = [
                                        _createTextVNode("Select Vendor")
                                      ])),
                                      _: 1
                                    }),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.workOrderVendors, (vendor) => {
                                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                                        key: vendor.id,
                                        value: vendor.id
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(vendor.company), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ion_item, {
                        lines: _ctx.assigneeType ? 'none' : 'full'
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select, {
                            modelValue: _ctx.assigneeType,
                            "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.assigneeType) = $event)),
                            label: "Assign To",
                            placeholder: "Not Set"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_select_option, { value: "" }, {
                                default: _withCtx(() => _cache[64] || (_cache[64] = [
                                  _createTextVNode("Not Selected")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_ion_select_option, { value: "TEAM_MEMBER" }, {
                                default: _withCtx(() => _cache[65] || (_cache[65] = [
                                  _createTextVNode("Team Member")
                                ])),
                                _: 1
                              }),
                              (_ctx.workOrderOwners?.length > 0)
                                ? (_openBlock(), _createBlock(_component_ion_select_option, {
                                    key: 0,
                                    value: "OWNER"
                                  }, {
                                    default: _withCtx(() => _cache[66] || (_cache[66] = [
                                      _createTextVNode("Owner")
                                    ])),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              (_ctx.store.workOrderVendors?.length > 0)
                                ? (_openBlock(), _createBlock(_component_ion_select_option, {
                                    key: 1,
                                    value: "VENDOR"
                                  }, {
                                    default: _withCtx(() => _cache[67] || (_cache[67] = [
                                      _createTextVNode("Vendor")
                                    ])),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["lines"]),
                      (_ctx.assigneeType)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                            default: _withCtx(() => [
                              (_ctx.assigneeType == 'TEAM_MEMBER')
                                ? (_openBlock(), _createBlock(_component_ion_select, {
                                    key: 0,
                                    placeholder: "Select Assignee",
                                    modelValue: _ctx.assignee,
                                    "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.assignee) = $event)),
                                    label: ""
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.WorkOrderTeamMembers, (member) => {
                                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                                          key: member.id,
                                          value: member.id
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(member.first_name + ' ' + member.last_name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"]))
                                : _createCommentVNode("", true),
                              (_ctx.assigneeType == 'OWNER')
                                ? (_openBlock(), _createBlock(_component_ion_select, {
                                    key: 1,
                                    placeholder: "Select Assignee",
                                    modelValue: _ctx.assignee,
                                    "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.assignee) = $event)),
                                    label: ""
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workOrderOwners, (owner) => {
                                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                                          key: owner.id,
                                          value: owner.id
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(owner.first_name + ' ' + owner.last_name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"]))
                                : _createCommentVNode("", true),
                              (_ctx.assigneeType == 'VENDOR')
                                ? (_openBlock(), _createBlock(_component_ion_select, {
                                    key: 2,
                                    placeholder: "Select Assignee",
                                    modelValue: _ctx.assignee,
                                    "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.assignee) = $event)),
                                    label: ""
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.workOrderVendors, (vendor) => {
                                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                                          key: vendor.id,
                                          value: vendor.id
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(vendor.company), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.assignee)
                        ? (_openBlock(), _createBlock(_component_ion_item, {
                            key: 2,
                            lines: _ctx.alsoNotifyType ? 'none' : 'full'
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_select, {
                                modelValue: _ctx.alsoNotifyType,
                                "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.alsoNotifyType) = $event)),
                                label: "Also Notify",
                                placeholder: "Not Set"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_select_option, { value: "" }, {
                                    default: _withCtx(() => _cache[68] || (_cache[68] = [
                                      _createTextVNode("Not Selected")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_select_option, { value: "TEAM_MEMBER" }, {
                                    default: _withCtx(() => _cache[69] || (_cache[69] = [
                                      _createTextVNode("Team Member")
                                    ])),
                                    _: 1
                                  }),
                                  (_ctx.workOrderOwners?.length > 0)
                                    ? (_openBlock(), _createBlock(_component_ion_select_option, {
                                        key: 0,
                                        value: "OWNER"
                                      }, {
                                        default: _withCtx(() => _cache[70] || (_cache[70] = [
                                          _createTextVNode("Owner")
                                        ])),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true),
                                  (_ctx.store.workOrderVendors?.length > 0)
                                    ? (_openBlock(), _createBlock(_component_ion_select_option, {
                                        key: 1,
                                        value: "VENDOR"
                                      }, {
                                        default: _withCtx(() => _cache[71] || (_cache[71] = [
                                          _createTextVNode("Vendor")
                                        ])),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }, 8, ["lines"]))
                        : _createCommentVNode("", true),
                      (_ctx.alsoNotifyType)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 3 }, {
                            default: _withCtx(() => [
                              (_ctx.alsoNotifyType == 'TEAM_MEMBER')
                                ? (_openBlock(), _createBlock(_component_ion_select, {
                                    key: 0,
                                    placeholder: "Select User to Notify",
                                    modelValue: _ctx.alsoNotify,
                                    "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.alsoNotify) = $event)),
                                    label: ""
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.WorkOrderTeamMembers, (member) => {
                                        return (_openBlock(), _createElementBlock(_Fragment, {
                                          key: member.id
                                        }, [
                                          (member.id != _ctx.assignee)
                                            ? (_openBlock(), _createBlock(_component_ion_select_option, {
                                                key: 0,
                                                value: member.id
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(member.first_name + ' ' + member.last_name), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["value"]))
                                            : _createCommentVNode("", true)
                                        ], 64))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"]))
                                : _createCommentVNode("", true),
                              (_ctx.alsoNotifyType == 'OWNER')
                                ? (_openBlock(), _createBlock(_component_ion_select, {
                                    key: 1,
                                    placeholder: "Select User to Notify",
                                    modelValue: _ctx.alsoNotify,
                                    "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.alsoNotify) = $event)),
                                    label: ""
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workOrderOwners, (owner) => {
                                        return (_openBlock(), _createElementBlock(_Fragment, {
                                          key: owner.id
                                        }, [
                                          (owner.id != _ctx.assignee)
                                            ? (_openBlock(), _createBlock(_component_ion_select_option, {
                                                key: 0,
                                                value: owner.id
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(owner.first_name + ' ' + owner.last_name), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["value"]))
                                            : _createCommentVNode("", true)
                                        ], 64))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"]))
                                : _createCommentVNode("", true),
                              (_ctx.alsoNotifyType == 'VENDOR')
                                ? (_openBlock(), _createBlock(_component_ion_select, {
                                    key: 2,
                                    placeholder: "Select User to Notify",
                                    modelValue: _ctx.alsoNotify,
                                    "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.alsoNotify) = $event)),
                                    label: ""
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.workOrderVendors, (vendor) => {
                                        return (_openBlock(), _createElementBlock(_Fragment, {
                                          key: vendor.id
                                        }, [
                                          (vendor.id != _ctx.assignee)
                                            ? (_openBlock(), _createBlock(_component_ion_select_option, {
                                                key: 0,
                                                value: vendor.id
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(vendor.company), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["value"]))
                                            : _createCommentVNode("", true)
                                        ], 64))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.assignee)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 4 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_checkbox, {
                                modelValue: _ctx.workOrderDetails.email_details,
                                "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.workOrderDetails.email_details) = $event)),
                                justify: "space-between",
                                style: {"font-family":"'San Francisco', sans-serif","font-size":"15px","font-weight":"500"}
                              }, {
                                default: _withCtx(() => _cache[72] || (_cache[72] = [
                                  _createTextVNode("Email Work Order Details")
                                ])),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_textarea, {
                            autoGrow: "",
                            style: {"margin-bottom":"10px"},
                            modelValue: _ctx.workOrderDetails.notes,
                            "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.workOrderDetails.notes) = $event)),
                            placeholder: "Enter Notes Here...",
                            label: "Notes",
                            labelPlacement: "floating"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", null, [
                        (_ctx.workOrderDetails.photos || _ctx.photos.length)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 0,
                              lines: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { style: {"font-family":"'San Francisco', sans-serif","font-size":"15px","font-weight":"500"} }, {
                                  default: _withCtx(() => _cache[73] || (_cache[73] = [
                                    _createTextVNode("Photos")
                                  ])),
                                  _: 1
                                }),
                                (_ctx.store.workOrderPermissions.has_modify_access)
                                  ? (_openBlock(), _createBlock(_component_ion_button, {
                                      key: 0,
                                      onClick: _cache[37] || (_cache[37] = ($event: any) => (_ctx.uploadPhoto())),
                                      style: {"aspect-ratio":"1/1","height":"44px","padding":"2px 0"},
                                      color: "primary"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_icon, { src: _ctx.add }, null, 8, ["src"])
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        ((_ctx.workOrderDetails.photos.length || _ctx.photos.length) && _ctx.isShowPhotos)
                          ? (_openBlock(), _createBlock(_component_swiper_container, {
                              key: 1,
                              class: "photos",
                              "slides-per-view": '1.5',
                              style: {"padding":"10px 0"}
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workOrderImages, (item, index) => {
                                  return (_openBlock(), _createBlock(_component_swiper_slide, { key: index }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_8, [
                                        _createElementVNode("div", _hoisted_9, [
                                          (_ctx.store.workOrderPermissions.has_modify_access && !item.deleted)
                                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                                key: 0,
                                                onClick: ($event: any) => (_ctx.downloadPhoto(item.id)),
                                                color: "light",
                                                style: {"position":"absolute","top":"0","left":"0","margin":"0px","height":"25px","aspect-ratio":"1/1","--border-radius":"10px 0 10px 0"}
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_ion_icon, {
                                                    src: _ctx.downloadOutline,
                                                    style: {"height":"25px"}
                                                  }, null, 8, ["src"])
                                                ]),
                                                _: 2
                                              }, 1032, ["onClick"]))
                                            : _createCommentVNode("", true),
                                          (_ctx.store.workOrderPermissions.has_modify_access && !item.deleted)
                                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                                key: 1,
                                                onClick: ($event: any) => (_ctx.deletePhoto(item.id, true)),
                                                color: "danger",
                                                style: {"position":"absolute","top":"0","right":"0","margin":"0px","height":"25px","aspect-ratio":"1/1","--border-radius":"0 10px 0 10px"}
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_ion_icon, {
                                                    src: _ctx.trash,
                                                    style: {"height":"25px"}
                                                  }, null, 8, ["src"])
                                                ]),
                                                _: 2
                                              }, 1032, ["onClick"]))
                                            : _createCommentVNode("", true),
                                          (_ctx.store.workOrderPermissions.has_add_access && item.deleted)
                                            ? (_openBlock(), _createBlock(_component_ion_icon, {
                                                key: 2,
                                                onClick: ($event: any) => (_ctx.updateCaption(item.id, true)),
                                                style: {"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)","z-index":"99 !important","width":"25%","height":"25%"},
                                                src: _ctx.refresh
                                              }, null, 8, ["onClick", "src"]))
                                            : _createCommentVNode("", true),
                                          _createElementVNode("img", {
                                            src: item.image || 'assets/img/no_image.png',
                                            onClick: ($event: any) => (_ctx.updateCaption(item.id, true)),
                                            style: _normalizeStyle([{"display":"flex","object-fit":"contain","border":"1px solid lightgray","border-radius":"10px","width":"100%"}, [
                                                    !item.caption
                                                        ? ''
                                                        : 'border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;',
                                                    item.deleted ? 'filter: grayscale(100%);' : '',
                                                ]])
                                          }, null, 12, _hoisted_10)
                                        ]),
                                        (item.caption)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(item.caption), 1))
                                          : _createCommentVNode("", true)
                                      ])
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128)),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.photos, (item, index) => {
                                  return (_openBlock(), _createBlock(_component_swiper_slide, { key: index }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_12, [
                                        _createElementVNode("div", _hoisted_13, [
                                          (_ctx.store.workOrderPermissions.has_modify_access)
                                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                                key: 0,
                                                onClick: ($event: any) => (_ctx.deletePhoto(index, false)),
                                                color: "danger",
                                                style: {"position":"absolute","top":"0","right":"0","margin":"0px","height":"25px","aspect-ratio":"1/1","--border-radius":"0 10px 0 10px"}
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_ion_icon, {
                                                    src: _ctx.trash,
                                                    style: {"height":"25px"}
                                                  }, null, 8, ["src"])
                                                ]),
                                                _: 2
                                              }, 1032, ["onClick"]))
                                            : _createCommentVNode("", true),
                                          (_ctx.store.workOrderPermissions.has_add_access && item.deleted)
                                            ? (_openBlock(), _createBlock(_component_ion_icon, {
                                                key: 1,
                                                onClick: ($event: any) => (_ctx.updateCaption(item.id, false)),
                                                style: {"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)","z-index":"99 !important","width":"25%","height":"25%"},
                                                src: _ctx.refresh
                                              }, null, 8, ["onClick", "src"]))
                                            : _createCommentVNode("", true),
                                          _createElementVNode("img", {
                                            src: item.photo_url,
                                            onClick: ($event: any) => (_ctx.updateCaption(index, false)),
                                            style: _normalizeStyle([{"display":"flex","object-fit":"contain","border":"1px solid lightgray","border-radius":"10px","width":"100%"}, [
                                                    !item.caption
                                                        ? ''
                                                        : 'border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;',
                                                    item.deleted ? 'filter: grayscale(100%);' : '',
                                                ]])
                                          }, null, 12, _hoisted_14)
                                        ]),
                                        (item.caption)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(item.caption), 1))
                                          : _createCommentVNode("", true)
                                      ])
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_ion_item, {
                              key: 2,
                              lines: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { style: {"margin":"0px","margin-bottom":"10px","font-style":"italic"} }, {
                                  default: _withCtx(() => _cache[74] || (_cache[74] = [
                                    _createTextVNode("No Photos Added")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.segmentValue == 'materials-and-expenses')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          (!_ctx.store.workOrderPermissions.is_modify_disabled && _ctx.workOrderId)
                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                key: 0,
                                style: {"--border-radius":"10px","aspect-ratio":"1/1","height":"30px","margin":"0 2px"},
                                color: "primary",
                                slot: "end",
                                onClick: _ctx.loadMaterialsExpenses
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { src: _ctx.refresh }, null, 8, ["src"])
                                ]),
                                _: 1
                              }, 8, ["onClick"]))
                            : _createCommentVNode("", true),
                          (!_ctx.store.workOrderPermissions.is_modify_disabled)
                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                key: 1,
                                style: {"--border-radius":"10px","aspect-ratio":"1/1","height":"30px","margin":"0 2px"},
                                color: "primary",
                                slot: "end",
                                onClick: _ctx.openCreateMaterialExpenseModal
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { src: _ctx.add }, null, 8, ["src"])
                                ]),
                                _: 1
                              }, 8, ["onClick"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.materialsExpenses || _ctx.materials, (materialExpense, index) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: materialExpense.id
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_17, [
                              _createVNode(_component_ion_label, { style: {"margin":"5px 0"} }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatDate(materialExpense.date)), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_label, {
                                style: _normalizeStyle([{"margin":"5px 0","display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical","overflow":"hidden","text-overflow":"ellipsis"}, [materialExpense.description ? '' : 'color:#a9a9a9']])
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(materialExpense.description
                                        ? 'Description: ' + materialExpense.description
                                        : 'No Description'), 1)
                                ]),
                                _: 2
                              }, 1032, ["style"]),
                              _createVNode(_component_ion_label, { style: {"margin":"5px 0"} }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Qty: " + _toDisplayString(materialExpense.qty) + " Price: " + _toDisplayString(materialExpense.price), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _createElementVNode("div", _hoisted_18, [
                              (!_ctx.store.workOrderPermissions.is_modify_disabled)
                                ? (_openBlock(), _createBlock(_component_ion_button, {
                                    key: 0,
                                    style: {"--border-radius":"10px","width":"100%","padding":"2px"},
                                    color: "primary",
                                    onClick: ($event: any) => (_ctx.editCreateMaterialExpenseModal(materialExpense))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, { src: _ctx.create }, null, 8, ["src"])
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true),
                              (!_ctx.store.workOrderPermissions.is_modify_disabled)
                                ? (_openBlock(), _createBlock(_component_ion_button, {
                                    key: 1,
                                    style: {"--border-radius":"10px","width":"100%","padding":"2px"},
                                    color: "danger",
                                    onClick: ($event: any) => (_ctx.deleteMaterialExpense(materialExpense.id || index))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, { src: _ctx.trashBin }, null, 8, ["src"])
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      ((!_ctx.materialsExpenses || _ctx.materialsExpenses.length <= 0) && _ctx.materials.length <= 0)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { style: {"margin":"15px","font-style":"italic","font-weight":"400","text-align":"center"} }, {
                                default: _withCtx(() => _cache[75] || (_cache[75] = [
                                  _createTextVNode("No Materials and Expenses Added")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.segmentValue == 'internal-notes')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createVNode(_component_ion_textarea, {
                        autoGrow: "",
                        style: {"padding":"5px 15px 10px"},
                        modelValue: _ctx.workOrderDetails.vendor_team_member_notes,
                        "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.workOrderDetails.vendor_team_member_notes) = $event)),
                        placeholder: "Enter Internal Notes Here...",
                        label: "Internal Notes",
                        labelPlacement: "stacked",
                        fill: "outline",
                        mode: "ios"
                      }, null, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}