<template>
    <modal-base-layout ref="modalBaseLayoutRef" title="Control Panel" background="rgb(239, 246, 255)" v-if="data">
        <div v-if="statusDetails" :class="statusDetails.class" :style="statusDetails.style">
            <p :style="statusDetails.titleStyle">
                <component :is="statusDetails.icon" :style="statusDetails.imgStyle" />{{ statusDetails.title }}
            </p>
            <p>
                Reservation ID: #{{ data.reservationId }}
                <ion-icon
                    @click="AppFunctions.copyText(data.reservationId)"
                    style="margin-bottom: -2px; cursor: pointer"
                    :icon="copyOutline"
                    label="Copy Reservation ID"
                ></ion-icon>
                <br /><br />
                {{ data.bookingMethodName }} • {{ data.createdDate }}
            </p>
        </div>

        <ion-segment mode="md" :scrollable="true" v-model="selectedSegment">
            <ion-segment-button value="reservation-details">
                <ion-label>Reservation Details</ion-label>
            </ion-segment-button>
            <ion-segment-button value="guest-details">
                <ion-label>Guest Information</ion-label>
            </ion-segment-button>
            <ion-segment-button value="payment-summary">
                <ion-label>Payment Summary</ion-label>
            </ion-segment-button>
            <ion-segment-button value="triggers">
                <ion-label>Triggers</ion-label>
            </ion-segment-button>
            <ion-segment-button value="housekeeping">
                <ion-label>Housekeeping</ion-label>
            </ion-segment-button>
        </ion-segment>

        <!--
            Reservation Details
        -->
        <ReservationDetailsSegment
            v-if="selectedSegment == 'reservation-details'"
            :data="data"
            :isMultipleProperties="isMultipleProperties"
            :tmpNotes="tmpNotes"
            :invoiceNumber="invoiceNumber"
            @fetchData="fetchData()"
        />

        <!--
            Guest Details
        -->
        <GuestDetailsSegment v-if="selectedSegment == 'guest-details'" :data="data" />

        <!--
            Payment Summary
        -->
        <PaymentSummarySegment v-if="selectedSegment == 'payment-summary'" :data="data" />

        <!--
            Housekeeping
        -->
        <HousekeepingSegment
            v-if="selectedSegment == 'housekeeping'"
            :data="data"
            :modalBaseLayoutRef="modalBaseLayoutRef"
            @fetchData="fetchData()"
        />

        <!--
            Triggers
        -->
        <TriggersSegment
            v-if="triggers && selectedSegment == 'triggers'"
            :triggers="triggers"
            :isMultipleProperties="isMultipleProperties"
            :invoiceNumber="invoiceNumber"
            @getTriggers="getTriggers"
        />

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="danger" @click="modalBaseLayoutRef.cancel()"
                            >Close</ion-button
                        >
                    </ion-col>
                    <ion-col>
                        <ion-button class="modal-footer-button" color="primary" @click="createNewReservation()"
                            >Add Reservation</ion-button
                        >
                    </ion-col>
                    <ion-col v-if="data.conversationId" style="max-width: fit-content !important; aspect-ratio: 1/1">
                        <ion-button style="width: fit-content !important" @click="openConversation(data.conversationId)"
                            ><ion-icon :src="chatbubbles" style="width: 24px; height: 24px"
                        /></ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, ref } from 'vue';
    import { copyOutline, chatbubbles } from 'ionicons/icons';
    import { format } from 'date-fns';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';
    import { controlPanelStatusMap } from '@/utils/constants';
    import AppAPI from '@/services/AppAPI';
    import ReservationDetailsSegment from './ReservationDetailsSegment.vue';
    import GuestDetailsSegment from './GuestDetailsSegment.vue';
    import PaymentSummarySegment from './PaymentSummarySegment.vue';
    import HousekeepingSegment from './HousekeepingSegment.vue';
    import TriggersSegment from './TriggersSegment.vue';

    export default defineComponent({
        components: {
            ReservationDetailsSegment,
            GuestDetailsSegment,
            PaymentSummarySegment,
            HousekeepingSegment,
            TriggersSegment,
        },
        props: {
            invoiceNumber: {
                type: String,
                required: true,
            },
        },
        setup(props) {
            const store = useStore();
            const modalBaseLayoutRef = ref();
            const tmpNotes = ref();
            const data = ref({} as any);
            const selectedSegment = ref('reservation-details');
            const triggers = ref([] as any);

            const isMultipleProperties = computed(() => {
                return data.value.properties?.length > 1;
            });

            onMounted(async () => {
                await fetchData();
                await getTriggers(props.invoiceNumber);
            });

            const fetchData = async () => {
                const reservation = await AppAPI.getReservationByInv(props.invoiceNumber);
                const createOnDate = format(new Date(reservation.data.created), 'MM/dd/yyyy');

                data.value = {
                    id: reservation.data.guest.id,
                    startDate: reservation.data.from_date,
                    endDate: reservation.data.to_date,
                    properties: reservation.data.properties,
                    status: reservation.data.state,
                    reservationId: props.invoiceNumber,
                    guest: reservation.data.guest,
                    stayType: reservation.data.stay_type,
                    createdDate: createOnDate,
                    guests: reservation.data.guests,
                    children: reservation.data.children,
                    checkInTime: reservation.data.check_in,
                    checkOutTime: reservation.data.check_out,
                    total: reservation.data.total,
                    confirmationAmount: reservation.data.confirmation_amount,
                    totalDeposits: reservation.data.total_with_deposits,
                    paid: reservation.data.paid,
                    substate: reservation.data.substate,
                    bookingMethodName: reservation.data.booking_method_name,
                    conversationId: reservation.data?.conversation_id || '',
                    notes: reservation.data.notes || '',
                    housekeeping: reservation.data.housekeeping || [],
                };

                data.value.housekeeping.forEach((task: any) => {
                    if (task.house_keeping_job) {
                        task.house_keeping_job.taskOptions = [
                            { value: 0, text: 'Regular Clean' },
                            { value: 1, text: 'Half Clean' },
                            { value: 2, text: 'Owner Departure Clean' },
                            { value: 3, text: 'Out of Service Clean' },
                        ];
                        task.house_keeping_job.statusOptions = [
                            { value: 0, text: 'To Do' },
                            { value: 1, text: 'In Progress' },
                            { value: 2, text: 'Done' },
                        ];
                    }
                });

                tmpNotes.value = data.value.notes;
            };

            const combineTriggers = (triggerList: any) => {
                const triggerMap = new Map();

                triggerList.forEach((trigger: any) => {
                    if (!triggerMap.has(trigger.id_trigger)) {
                        triggerMap.set(trigger.id_trigger, { ...trigger, sub_triggers: [] });
                    } else {
                        triggerMap.get(trigger.id_trigger).sub_triggers.push(trigger);
                    }
                });

                return Array.from(triggerMap.values());
            };

            // Usage
            const getTriggers = async (invoiceNumber: any) => {
                const response = await AppAPI.getInvoiceTriggers(invoiceNumber);
                let triggerList = response.data;
                triggerList.sort((a: any, b: any) => {
                    if (a.trigger_title < b.trigger_title) {
                        return -1;
                    }
                    if (a.trigger_title > b.trigger_title) {
                        return 1;
                    }
                    return 0;
                });

                if (isMultipleProperties.value) {
                    triggerList = combineTriggers(triggerList);
                }

                triggers.value = triggerList;
            };

            const statusDetails = computed(() => {
                const map: { [key: string]: any } = controlPanelStatusMap(data.value);
                return map[data.value.status] || null;
            });

            const createNewReservation = () => {
                store.changeReservationFirstName(data.value.guest?.first_name);
                store.changeReservationLastName(data.value.guest?.last_name);
                store.changeReservationEmail(data.value.guest?.email);
                store.changeReservationGuestId(data.value.guest?.id);
                AppFunctions.transitionClear('/createReservation');
                modalBaseLayoutRef.value.cancel();
            };

            const openConversation = (conversationId: string) => {
                AppFunctions.openChat(conversationId);
                modalBaseLayoutRef.value.cancel();
            };

            return {
                copyOutline,
                modalBaseLayoutRef,
                AppFunctions,
                statusDetails,
                tmpNotes,
                data,
                selectedSegment,
                chatbubbles,
                AppAPI,
                triggers,
                isMultipleProperties,
                getTriggers,
                fetchData,
                createNewReservation,
                openConversation,
            };
        },
    });
</script>

<style scoped>
    .statusCard {
        border-radius: 10px !important;
        margin: 10px !important;
        margin-bottom: 0px !important;
        text-align: center !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
    }

    ion-item {
        --background: transparent;
    }

    ion-list {
        background: transparent !important;
    }

    .address {
        text-align: right;
        font-size: small;
    }
</style>
