import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/capacitor';
import { app } from '@/main';
import { useStore } from '@/store/store';

export async function setupSentry() {
    const store = useStore();
    const useSentry = process.env.VUE_APP_URL_BASE == 'https://www.lodgix.com' && Capacitor.getPlatform() !== 'web';

    let user = undefined;
    if (store.userEmail) {
        user = {
            id: store.Id,
            email: store.userEmail,
        };
    }

    if (useSentry) {
        console.log('Setting up Sentry', useSentry, user);

        Sentry.init({
            app,
            dsn: process.env.VUE_APP_SENTRY_DSN,
            release: 'lodgix-admin-app@' + process.env.VUE_APP_VERSION,
            dist: '1',
            ignoreErrors: ['CanceledError'],

            beforeSend(event, hint) {
                if (hint && hint.originalException) {
                    const exception = hint.originalException as Error;
                    if (exception.stack && exception.stack.includes('unified-inbox/inbox-component')) {
                        return null;
                    }
                }
                // Handle non-error objects
                if (event.exception && event.exception.values && event.exception.values[0]) {
                    const exception = event.exception.values[0];
                    if (
                        exception.type === 'Error' &&
                        exception.value === 'Object captured as exception with keys: error, headers, message, name, ok'
                    ) {
                        // Customize the event or discard it
                        return null;
                    }
                }
                return event;
            },

            initialScope: {
                user: user,
            },
        });
    } else {
        console.log('Not setting up Sentry, application in development.', useSentry, user);
    }
}
